<template>
  <div v-if="showWordFollowDialog" class="word-follow-dialog">
    <!-- 遮罩层 -->
    <div class="mask" @click="$emit('onWordFollowClose')"></div>

    <div v-if="wordData.length > 0" class="word-follow-container">
      <!-- 关闭按钮 -->
      <i
        class="el-icon-close close-icon"
        @click="$emit('onWordFollowClose')"
      ></i>
      <!-- 单词 -->
      <div class="title">{{ showData.word }}</div>
      <!-- 提示 -->
      <div class="rate-tip" v-if="showData.status === 4">
        <el-rate
          v-model="showData.score"
          disabled
          show-score
          text-color="#000"
          score-template="{value} 星"
        ></el-rate>
      </div>
      <div class="tip" v-else>
        {{ showData.status === 5 ? showData.err : statusMap[showData.status] }}
      </div>
      <!-- 麦克风图标 -->
      <div class="follow-image" @click="toggleStatus">
        <div class="follow-image-wave" v-if="showData.status === 2"></div>
        <img class="image" :src="recordIcon" />
      </div>
      <!-- 底部 -->
      <div class="footer">
        <!-- 前一个单词 -->
        <div class="word-control">
          <div class="word-area">{{ preWord }}</div>
          <i
            class="el-icon-caret-left arrow-icon"
            :style="{ color: showIndex === 0 ? '#ddd' : '' }"
            @click="toPre"
          ></i>
        </div>
        <!-- 中间数量展示 -->
        <div class="mid">{{ showIndex + 1 }}/{{ wordData.length }}</div>
        <!-- 下一个单词 -->
        <div class="word-control">
          <i
            class="el-icon-caret-right arrow-icon"
            :style="{ color: showIndex === wordData.length - 1 ? '#ddd' : '' }"
            @click="toNext"
          ></i>
          <div class="word-area" style="text-align: left">{{ nextWord }}</div>
        </div>
      </div>
    </div>
    <div v-else class="word-follow-noword-container">
      <!-- 关闭按钮 -->
      <i
        class="el-icon-close close-icon"
        @click="$emit('onWordFollowClose')"
      ></i>
      <!-- 提示 -->
      <div class="tip">本课件未设置跟读单词</div>
    </div>
  </div>
</template>

<script>
import recordIcon from "@/assets/images/icon/record_icon@2x.png";

const statusMap = {
  1: "点击跟读",
  2: "检测中",
  3: "未检测到任何发言",
  4: "检测完成",
  5: "err",
};

export default {
  name: "word-follow",
  props: {
    // 是否显示
    showWordFollowDialog: {
      type: Boolean,
      default: false,
    },
    // 单词数据
    wordData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      recordIcon,
      statusMap,
      showIndex: +sessionStorage.getItem("wordFollowIndex") || 0,
      myRecord: {},
      appid: "a153", // 必须写死a153
      userId: "hailiang_aiyunketang", // 自定义
      warrantId: "",
    };
  },
  computed: {
    // 当前展示的单词
    showData() {
      return this.wordData[this.showIndex];
    },
    // 上一个单词
    nextWord() {
      return this.wordData[this.showIndex + 1]?.word || "";
    },
    // 下一个单词
    preWord() {
      return this.wordData[this.showIndex - 1]?.word || "";
    },
  },
  watch: {
    showIndex(val) {
      sessionStorage.setItem("wordFollowIndex", val);
    },
    wordData: {
      handler(val) {
        sessionStorage.setItem("wordFollowData", JSON.stringify(val));
      },
      deep: true,
    },
    showWordFollowDialog(val) {
      if (val && this.wordData.length) {
        this.showIndex = +sessionStorage.getItem("wordFollowIndex") || 0;
        this.initEngine();
      } else {
        this.myRecord.destroyEngine();
        this.myRecord = null;
      }
    },
  },
  methods: {
    // 调用接口获取warrant_id
    handleGetWarrantId() {
      let _this = this;
      let url = "https://warrant.api.cloud.ssapi.cn";
      let xhr = new XMLHttpRequest();
      xhr.open("POST", url, false);
      xhr.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded;charset=UTF-8"
      );
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          let res = JSON.parse(xhr.responseText);
          if (res?.data?.warrant_id) {
            _this.warrantId = res.data.warrant_id;
          } else {
            _this.$message.error("获取warrant_id失败");
          }
        }
      };
      xhr.send(`user_id=${_this.userId}&app_id=${_this.appid}`);
    },
    // 初始化录音检测
    async initEngine() {
      let _this = this;
      _this.handleGetWarrantId();
      const EngineEvaluat = window.EngineEvaluat;

      _this.myRecord = new EngineEvaluat({
        applicationId: _this.appid,
        userId: _this.userId,

        micForbidCallback: function () {
          _this.$message.error("麦克风没有被允许");
        },

        // 引擎评测方法
        engineBackResultDone: function (msg) {
          _this.handleResultCallBack(msg);
        },
        engineBackResultFail: function (msg) {
          const error = JSON.parse(msg);
          if (error.errId === 41030) {
            // warrantId缺失或者warrantId错误，重新获取warrantId
            handleGetWarrantId();
          } else if (error.errId === 40092) {
            // 传输的音频时长超限 如果不设置evalTime 默认30s超时
            // _this.$message.error("录音时长超限");
            _this.handleErrorCallBack("录音时长超限");
          }
        },

        // 网络
        noNetwork: function () {
          _this.$message.error("网络连接失败");
        },

        // 不支持
        JSSDKNotSupport: function () {
          _this.$message.error("当前浏览器环境不支持");
        },
      });
    },
    // 录音结果回调
    handleResultCallBack(res) {
      const recordResult = JSON.parse(res);
      // 接口字段 https://open.singsound.com/doc/engine?type=engine-en-en.word.score&detail=false
      // tipid === 10000 => 没有检测到声音
      if (
        [10000, 10002, 10003, 10004, 10005, 10006, 10008].includes(
          recordResult.result.info.tipId
        )
      ) {
        this.showData.status = 3;
      } else {
        // 评分
        const pointer = recordResult.result.overall;
        // 总分
        const totalPointer = recordResult.result.rank;
        this.showData.status = 4;
        this.showData.score = parseFloat(
          ((pointer / totalPointer) * 5).toFixed(1)
        );
      }
    },

    //录音错误回调
    handleErrorCallBack(err) {
      // 修改错误提示
      this.showData.err = err;
      // 切换状态
      this.showData.status = 5;
    },

    // 点击麦克风图标切换检测状态
    toggleStatus() {
      switch (this.showData.status) {
        case 1:
        case 3:
        case 4:
        case 5:
          // 点击后开始录音
          this.showData.status = 2;
          this.myRecord.startRecord({
            coreType: "en.word.score",
            refText: this.showData.word,
            warrantId: this.warrantId,
          });
          break;
        case 2:
          // 点击后结束录音
          this.myRecord.stopRecord();
          break;
      }
    },

    // 向前切换单词
    toPre() {
      if (this.showIndex === 0 || this.showData.status === 2) return;
      this.showIndex -= 1;
    },

    // 向后切换单词
    toNext() {
      if (
        this.showIndex === this.wordData.length - 1 ||
        this.showData.status === 2
      )
        return;
      this.showIndex += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .el-rate__icon {
  font-size: 30px;
}

* {
  font-style: normal;
  user-select: none;
}

.word-follow-dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  animation: enter 0.2s linear;

  @keyframes enter {
    0% {
      top: -10%;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .word-follow-noword-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    height: 180px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    .close-icon {
      position: absolute;
      right: 25px;
      top: 25px;
      font-size: 30px;
      cursor: pointer;
    }
    .tip {
      width: 100%;
      text-align: center;
      line-height: 180px;
    }
  }

  .word-follow-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 740px;
    height: 480px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    .close-icon {
      position: absolute;
      right: 25px;
      top: 25px;
      font-size: 30px;
      cursor: pointer;
    }

    .title {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      font-size: 36px;
      color: #333333;
      line-height: 50px;
      text-align: center;
    }

    .rate-tip {
      position: absolute;
      top: 153px;
      left: 50%;
      transform: translateX(-50%);
    }

    .tip {
      position: absolute;
      top: 153px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      font-size: 24px;
      color: #bbbbbb;
      line-height: 33px;
      text-align: center;
    }

    .follow-image {
      position: absolute;
      top: 216px;
      left: 50%;
      transform: translateX(-50%);
      width: 132px;
      height: 132px;
      border-radius: 50%;
      background-color: #6448ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .image {
        width: 58px;
        height: 58px;
        object-fit: cover;
      }

      .follow-image-wave {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 50%;
        animation: animate 1.5s linear infinite;
        background-color: #6448ff;
        z-index: -1;
      }

      @keyframes animate {
        0% {
          transform: scale(1);
          opacity: 0.4;
        }
        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }
    }

    .footer {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 0;
      right: 0;
      height: 90px;
      border-top: 2px solid #f0f4f8;

      .word-control {
        width: 304px;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
      }

      .mid {
        width: 128px;
        height: 100%;
        flex-shrink: 0;
        border-right: 2px solid #f0f4f8;
        border-left: 2px solid #f0f4f8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        text-align: center;
      }

      .arrow-icon {
        flex: 1;
        font-size: 32px;
        text-align: center;
        cursor: pointer;
      }
      .word-area {
        flex: 3;
        font-weight: bold;
        font-size: 24px;
        color: #bbbbbb;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
