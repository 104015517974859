var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"drag",rawName:"v-drag"}],staticClass:"float-menu"},[_c('div',{ref:"menuNav",staticClass:"menu-nav move_nav",on:{"click":_vm.packUp}},[_c('i',{staticClass:"icon-nav"})]),_c('div',{ref:"menuContent",staticClass:"menu-content move_content"},[(!_vm.$store.state.user.isThirdSign)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('random-questions')}}},[_c('img',{staticClass:"toolicon",attrs:{"src":_vm.robit,"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("el.teaching.randomQuestion"))+" ")]):_vm._e(),(!_vm.$store.state.user.isThirdSign)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('call-roll')}}},[_c('img',{staticClass:"toolicon",attrs:{"src":_vm.calltheroll,"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("el.teaching.rollCall"))+" ")]):_vm._e(),(!_vm.$store.state.user.isThirdSign)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('word-follow')}}},[_c('img',{staticClass:"toolicon",attrs:{"src":_vm.wordFollowIcon,"alt":""}}),_vm._v(" 单词跟读 ")]):_vm._e(),(_vm.fullscreen)?_c('div',{staticClass:"painting",class:{
          disabled: _vm.status,
        },on:{"click":function($event){$event.stopPropagation();!_vm.status && _vm.$emit('switch-status')},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-painting"}),_vm._v(_vm._s(_vm.$t("el.teaching.draw"))+" ")]):_vm._e(),(_vm.count > 1 || _vm.fullscreen)?_c('div',{staticClass:"clear",on:{"click":function($event){$event.stopPropagation();return _vm.onClear.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-clear"}),_vm._v(_vm._s(_vm.$t("el.teaching.clearPage"))+" ")]):_vm._e(),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.switchFileList.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('img',{staticClass:"toolicon",attrs:{"src":_vm.switchFile,"alt":""}}),_c('div',{staticClass:"flex flex-m"},[_vm._v(" "+_vm._s(_vm.$t("el.teaching.courseResources"))+" ")])])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fileListLoading),expression:"fileListLoading"}],ref:"fileListBox",staticClass:"menu-file-list",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('el-tabs',{staticClass:"tab_manage_menu",on:{"tab-click":_vm.changeResourceTab},model:{value:(_vm.activeResourceName),callback:function ($$v) {_vm.activeResourceName=$$v},expression:"activeResourceName"}},_vm._l((_vm.resourceTabList),function(item){return _c('el-tab-pane',{key:item.name,attrs:{"name":item.name,"label":_vm.$t(item.label)}})}),1),_c('div',{staticClass:"file-list-box"},[_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.groupResource"))+" ")]),(_vm.fileData.standardList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.standardList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
            _vm.getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ? '' : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(item.resourceName)+" ")])])}),_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.schoolResource"))+" ")]),(_vm.fileData.collectiveList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.collectiveList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
            _vm.getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ? '' : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(item.resourceName)+" ")])])}),_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.myResourse"))+" ")]),(_vm.fileData.personalList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.personalList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
            _vm.getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ? '' : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(item.resourceName)+" ")])])})],2)],1),(_vm.showTip)?_c('div',{ref:"switchTips",staticClass:"switch-tips switch_tips",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"tip-close"},[_c('img',{attrs:{"src":require("@/assets/images/icon/close_btn_icon@2x.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.hiddenTip.apply(null, arguments)}}})]),_c('div',{staticClass:"tip-content"},[_c('img',{staticClass:"tip-bg",attrs:{"src":require("@/assets/images/icon/tip_bg@2x.png"),"alt":""}}),_c('div',{staticClass:"tip-text"},[_c('img',{staticClass:"tip-click-icon",attrs:{"src":require("@/assets/images/icon/click_icon@2x.png"),"alt":""}}),_c('div',{staticClass:"tip-text-content"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.switchTip"))+" ")])])])]):_vm._e()]),_c('preview-files',{attrs:{"showPreviewDialog":_vm.showPreviewDialog,"fileType":_vm.fileType,"file":_vm.currentFile,"playUrl":_vm.playUrl},on:{"close-dialog":_vm.closePreviewDialog}}),(_vm.previewShow)?_c('el-image-viewer',{staticStyle:{"color":"#fff"},attrs:{"on-close":() => {
        _vm.previewShow = false;
      },"url-list":[_vm.previewImg]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }