<template>
  <div>
    <div v-drag class="float-menu">
      <div ref="menuNav" class="menu-nav move_nav" @click="packUp">
        <i class="icon-nav"></i>
      </div>
      <div ref="menuContent" class="menu-content move_content">
        <!-- 这里一个 -->
        <div
          v-if="!$store.state.user.isThirdSign"
          @click.stop="$emit('random-questions')"
        >
          <img class="toolicon" :src="robit" alt="" />
          {{ $t("el.teaching.randomQuestion") }}
        </div>
        <div
          v-if="!$store.state.user.isThirdSign"
          @click.stop="$emit('call-roll')"
        >
          <img class="toolicon" :src="calltheroll" alt="" />
          {{ $t("el.teaching.rollCall") }}
        </div>
        <div
          v-if="!$store.state.user.isThirdSign"
          @click.stop="$emit('word-follow')"
        >
          <img class="toolicon" :src="wordFollowIcon" alt="" />
          单词跟读
        </div>
        <div
          v-if="fullscreen"
          class="painting"
          :class="{
            disabled: status,
          }"
          @click.stop="!status && $emit('switch-status')"
          @mousedown.stop
        >
          <i class="icon-painting"></i>{{ $t("el.teaching.draw") }}
        </div>
        <div
          class="clear"
          v-if="count > 1 || fullscreen"
          @click.stop="onClear"
          @mousedown.stop
        >
          <i class="icon-clear"></i>{{ $t("el.teaching.clearPage") }}
        </div>
        <div @click.stop="switchFileList" @mousedown.stop>
          <img class="toolicon" :src="switchFile" alt="" />
          <div class="flex flex-m">
            {{ $t("el.teaching.courseResources") }}
            <!-- <img
            style="width: 10px; height: 10px; margin-left: 4px"
            :src="require('../../../assets/images/icon/icon-arrow-double.png')"
            alt=""
          /> -->
          </div>
        </div>
      </div>
      <div
        ref="fileListBox"
        class="menu-file-list"
        @mousedown.stop
        v-loading="fileListLoading"
      >
        <el-tabs
          @tab-click="changeResourceTab"
          class="tab_manage_menu"
          v-model="activeResourceName"
        >
          <el-tab-pane
            v-for="item in resourceTabList"
            :key="item.name"
            :name="item.name"
            :label="$t(item.label)"
          ></el-tab-pane>
        </el-tabs>
        <div class="file-list-box">
          <div class="file-type-title">
            {{ $t("el.classroomWps.groupResource") }}
          </div>
          <div v-if="fileData.standardList.length === 0" class="no-file">
            {{ $t("el.common.noResources") }}
          </div>
          <div
            v-for="item in fileData.standardList"
            :key="item.resourceId"
            class="file-item"
            :class="
              resourceId == item.resourceId ||
              getResourceType(item.format) === 'otherType'
                ? 'file-item-disabled'
                : ''
            "
            @click.stop="
              resourceId == item.resourceId ? '' : changeFilePage(item)
            "
          >
            <img
              :src="item.resourceUrl | getFileType"
              alt=""
              class="file-icon"
            />
            <!-- <tooltip-over
              :content="item.resourceName"
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :singleAble="false"
            ></tooltip-over> -->
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              slot="reference"
            >
              {{ item.resourceName }}
            </p>
            <!-- <p class="file-name" slot="reference">{{ item.resourceName }}</p>
          </el-popover> -->
          </div>
          <div class="file-type-title">
            {{ $t("el.classroomWps.schoolResource") }}
          </div>
          <div v-if="fileData.collectiveList.length === 0" class="no-file">
            {{ $t("el.common.noResources") }}
          </div>
          <div
            v-for="item in fileData.collectiveList"
            :key="item.resourceId"
            class="file-item"
            :class="
              resourceId == item.resourceId ||
              getResourceType(item.format) === 'otherType'
                ? 'file-item-disabled'
                : ''
            "
            @click.stop="
              resourceId == item.resourceId ? '' : changeFilePage(item)
            "
          >
            <img
              :src="item.resourceUrl | getFileType"
              alt=""
              class="file-icon"
            />
            <!-- <tooltip-over
              :content="item.resourceName"
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :singleAble="false"
            ></tooltip-over> -->
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              slot="reference"
            >
              {{ item.resourceName }}
            </p>
          </div>
          <div class="file-type-title">
            {{ $t("el.classroomWps.myResourse") }}
          </div>
          <div v-if="fileData.personalList.length === 0" class="no-file">
            {{ $t("el.common.noResources") }}
          </div>
          <div
            v-for="item in fileData.personalList"
            :key="item.resourceId"
            class="file-item"
            :class="
              resourceId == item.resourceId ||
              getResourceType(item.format) === 'otherType'
                ? 'file-item-disabled'
                : ''
            "
            @click.stop="
              resourceId == item.resourceId ? '' : changeFilePage(item)
            "
          >
            <img
              :src="item.resourceUrl | getFileType"
              alt=""
              class="file-icon"
            />
            <!-- <tooltip-over
              :content="item.resourceName"
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :singleAble="false"
            ></tooltip-over> -->
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              slot="reference"
            >
              {{ item.resourceName }}
            </p>
          </div>
        </div>
      </div>
      <div
        ref="switchTips"
        class="switch-tips switch_tips"
        v-if="showTip"
        @mousedown.stop
      >
        <div class="tip-close">
          <img
            src="@/assets/images/icon/close_btn_icon@2x.png"
            @click.stop="hiddenTip"
            alt=""
          />
        </div>
        <div class="tip-content">
          <img class="tip-bg" src="@/assets/images/icon/tip_bg@2x.png" alt="" />
          <div class="tip-text">
            <img
              class="tip-click-icon"
              src="@/assets/images/icon/click_icon@2x.png"
              alt=""
            />
            <div class="tip-text-content">
              {{ $t("el.classroomWps.switchTip") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <preview-files
      :showPreviewDialog="showPreviewDialog"
      :fileType="fileType"
      :file="currentFile"
      :playUrl="playUrl"
      @close-dialog="closePreviewDialog"
    ></preview-files>
    <el-image-viewer
      v-if="previewShow"
      style="color: #fff"
      :on-close="
        () => {
          previewShow = false;
        }
      "
      :url-list="[previewImg]"
    />
  </div>
</template>

<script>
import Whiteboard from "whiteboard";
import drag from "@/directive/drag/index";
import { queryResource, noticeCheck } from "@/api/classroom";
import robit from "@/assets/images/icon/icon_taach_pick@2x.png";
import calltheroll from "@/assets/images/icon/icon_taach_list@2x.png";
import wordFollowIcon from "@/assets/images/icon/icon_teach_word_follow.png";
import switchFile from "@/assets/images/icon/icon-course-resource.png";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import PreviewFiles from "@/components/biz-components/PreviewFiles/index.vue";
import { getObsUrl } from "../../../api/upload.js";
import { async } from "q";
export default {
  name: "float-menu",
  components: {
    // tooltipOver,
    PreviewFiles,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    /**
     * 绘制状态
     */
    status: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否全屏状态
     */
    fullscreen: Boolean,
  },
  directives: {
    drag,
  },
  created() {
    this.noticeCheck();
  },
  // watch: {
  //   $route(to, from) {
  //     this.$router.go(0);
  //   },
  // },
  data() {
    return {
      robit: robit,
      showPreviewDialog: false, // 预览其他文件
      fileType: "", // 文件类型
      currentFile: {},
      playUrl: "", // 播放的url
      previewShow: false,
      calltheroll: calltheroll,
      wordFollowIcon,
      switchFile: switchFile,
      showTip: false,
      w: null, // 白板对象
      pointer: 0, // 指针
      count: 1, // 记录数
      navOpen: false,
      fileListOpen: false,
      id: this.$route.query.id,
      fileData: { standardList: [], collectiveList: [], personalList: [] },
      resourceId: this.$route.query.resourceId,
      fileListLoading: false, // 文件列表的loading
      activeResourceName: "KJ",
    };
  },
  filters: {
    getFileType(resourceUrl) {
      let ppt = require("@/assets/images/icon/pic_format_ppt@2x.png");
      let doc = require("@/assets/images/icon/pic_format_word@2x.png");
      let excel = require("@/assets/images/icon/excel.png");
      let pdf = require("@/assets/images/icon/pdf.png");
      let mp4 = require("@/assets/images/icon/pic_format_mp4@2x.png");
      let otherfile = require("@/assets/images/icon/other-file.png");
      let type =
        resourceUrl.indexOf("pptx") > -1 ||
        resourceUrl.indexOf("ppt") > -1 ||
        resourceUrl.indexOf("PPTX") > -1 ||
        resourceUrl.indexOf("PPT") > -1
          ? ppt
          : resourceUrl.indexOf("doc") > -1 ||
            resourceUrl.indexOf("docx") > -1 ||
            resourceUrl.indexOf("DOC") > -1 ||
            resourceUrl.indexOf("DOCX") > -1
          ? doc
          : resourceUrl.indexOf("mp4") > -1 ||
            resourceUrl.indexOf("MP4") > -1 ||
            resourceUrl.indexOf("mov") > -1 ||
            resourceUrl.indexOf("MOV") > -1
          ? mp4
          : resourceUrl.indexOf("pdf") > -1 || resourceUrl.indexOf("PDF") > -1
          ? pdf
          : resourceUrl.indexOf("xls") > -1 ||
            resourceUrl.indexOf("xlsx") > -1 ||
            resourceUrl.indexOf("XLS") > -1 ||
            resourceUrl.indexOf("XLSX") > -1
          ? excel
          : otherfile;
      return type;
    },
  },
  computed: {
    resourceTabList() {
      return [
        {
          name: "KJ",
          label: this.$t("el.classroomWps.courseware"),
        },
        {
          name: "JA",
          label: this.$t("el.classroomWps.lessonPlan"),
        },
        {
          name: "KTZL",
          label: this.$t("el.classroomWps.classmaterials"),
        },
        {
          name: "BKSP",
          label: this.$t("el.classroomWps.lessonVideo"),
        },
        {
          name: "ZY",
          label: this.$t("el.classroomWps.worksheet"),
        },
        {
          name: "KQYX",
          label: this.$t("el.classroomWps.homework"),
        },
      ];
    },
  },
  methods: {
    /**
     * 初始化
     */
    init(w) {
      if (!(w instanceof Whiteboard)) {
        console.warn("参数错误：非白板对象");
        return;
      }
      this.w = w;
      // 历史改变
      this.w.$on("change-history", (pointer, count) => {
        this.pointer = w.pointer;
        this.count = w.store.length;
      });
    },
    changeResourceTab(tab) {
      console.log("tab=======", tab);
      let tabName = tab.name;
      this.toQueryResource(tabName);
    },
    /**
     * 清除白板
     */
    change() {
      console.log("456456456456");
    },
    onClear() {
      if (!this.w) return;
      this.w.clear();
      this.$message.success(this.$t("el.teaching.clearSuccess"));
      if (!this.status) {
        console.log("qingchu");
        document.querySelector("#newwpsfile iframe").focus();
      }
    },
    /**
     * 点击收起或者展开
     */
    packUp() {
      this.$nextTick(() => {
        this.navOpen = !this.navOpen;
        if (!this.navOpen) {
          this.$refs.menuContent &&
            this.$refs.menuContent.classList.remove("move_on_content");
          this.$refs.menuContent &&
            this.$refs.menuContent.classList.add("move_content");
          this.$refs.menuNav &&
            this.$refs.menuNav.classList.remove("move_on_nav");
          this.$refs.menuNav && this.$refs.menuNav.classList.add("move_nav");
          this.$refs.fileListBox &&
            this.$refs.fileListBox.classList.remove("move_file_list");
          this.$refs.fileListBox &&
            this.$refs.fileListBox.classList.add("move_on_file_list");
          this.$refs.switchTips &&
            this.$refs.switchTips.classList.remove("switch_on_tips");
          this.$refs.switchTips &&
            this.$refs.switchTips.classList.add("switch_tips");
          this.showTip = false;
          if (this.fileListOpen) {
            this.fileListOpen = !this.fileListOpen;
          }
        } else {
          this.$refs.menuContent &&
            this.$refs.menuContent.classList.remove("move_content");
          this.$refs.menuContent &&
            this.$refs.menuContent.classList.add("move_on_content");
          this.$refs.menuNav.classList &&
            this.$refs.menuNav.classList.remove("move_nav");
          this.$refs.menuNav.classList &&
            this.$refs.menuNav.classList.add("move_on_nav");
          this.$refs.switchTips &&
            this.$refs.switchTips.classList.remove("switch_tips");
          this.$refs.switchTips &&
            this.$refs.switchTips.classList.add("switch_on_tips");
        }
      });
    },
    // 打开或者隐藏课件列表
    switchFileList() {
      this.$nextTick(() => {
        this.showTip = false;
        this.fileListOpen = !this.fileListOpen;
        if (!this.fileListOpen) {
          this.$refs.fileListBox.classList.remove("move_file_list");
          this.$refs.fileListBox.classList.add("move_on_file_list");
        } else {
          this.$refs.fileListBox.classList.remove("move_on_file_list");
          this.$refs.fileListBox.classList.add("move_file_list");
          this.toQueryResource(this.activeResourceName || "KJ");
        }
      });
    },
    toQueryResource(name) {
      this.fileListLoading = true;
      return queryResource(name, {
        knowledgeId: this.id,
        originTenantId: this.$route.query.originTenantId,
      })
        .then((res) => {
          this.fileData = res.data;
          this.fileListLoading = false;
        })
        .catch(() => {
          this.fileListLoading = false;
        });
    },
    noticeCheck() {
      let params = { pageRoute: this.$route.path, versionCode: 19 };
      noticeCheck(params).then((res) => {
        if (res.data) {
          this.showTip = false;
          this.navOpen = false;
          return;
        } else {
          this.showTip = true;
          this.navOpen = true;
        }
        this.$nextTick(() => {
          if (!res.data) {
            this.showTip = true;
            this.$refs.menuContent.classList.remove("move_content");
            this.$refs.menuContent.classList.add("move_on_content");
            this.$refs.menuNav.classList.remove("move_nav");
            this.$refs.menuNav.classList.add("move_on_nav");
            this.$refs.switchTips.classList.remove("switch_tips");
            this.$refs.switchTips.classList.add("switch_on_tips");
          }
        });
      });
    },
    // 关闭提示
    hiddenTip() {
      console.log("4564645");
      this.showTip = false;
    },
    async changeFilePage(item) {
      console.log("resourceitem======", item);
      // 重置 预览 显示
      this.showPreviewDialog = false;
      this.previewShow = false;
      this.$nextTick(async () => {
        this.currentFile = item;
        if (item.resourceType === "KJ") {
          // let query = Object.assign({resourceId: resourceId}, this.$route.query)
          let query = { ...this.$route.query };
          query.resourceId = item.resourceId;
          query.version = item.version;
          query.format = item.format;
          query.resourceName = item.resourceName;
          this.$router.replace({ query });
          this.resourceId = item.resourceId;
          let obj = {
            resource: item,
            resourceId: item.resourceId,
            classId: this.$route.query.classId,
            id: this.$route.query.classId,
            format: item.format,
            version: item.version,
            gradeId: this.$route.query.gradeId,
            classType: this.$route.query.classType,
            className: this.$route.query.className,
            schoolCode: this.$route.query.schoolCode,
            resourceName: item.resourceName,
          };
          this.wpsConfig.destroy();
          this.$emit("changewpscurryPage", 1, 1);
          // this.$parent.selectBack(obj,1)
          this.$emit("selectBack", obj, 1);
          // this.packUp();
        } else {
          console.log("非课件=============");
          this.fileType = this.getResourceType(item.format);
          if (["video", "audio", "image"].indexOf(this.fileType) > -1) {
            try {
              let a = await this.getPlayUrl(item.resourceUrl, item.resourceUrl);
              console.log("a=====", a);
              this.playUrl = a;
              if (this.fileType === "image") {
                this.previewShow = true;
                this.previewImg = a;
              } else {
                this.showPreviewDialog = true;
              }
            } catch (error) {
              console.log("error====", error);
            }
          } else if (this.fileType === "wpsFile") {
            //
            this.showPreviewDialog = true;
          } else {
            //
            this.$message.info(this.$t("el.classroomWps.notSupportPreview"));
          }
        }
      });
    },
    // 获取播放链接
    getPlayUrl(objectName, displayName) {
      const objectType = objectName.substring(objectName.lastIndexOf(".") + 1);
      const OBSDIR = process.env.VUE_APP_OBS_VOD_VIDEO_DIR;
      if (["mp4", "MP4", "mov", "MOV"].indexOf(objectType) > -1) {
        if (!objectName.includes(OBSDIR)) {
          let prefix = OBSDIR;
          if (!objectName.startsWith("/")) {
            prefix = `${OBSDIR}/`;
          }
          objectName = prefix + objectName;
        }
      }
      let params = {
        objectName,
        displayName,
        isDownload: 0,
      };
      return getObsUrl(params).then((result) => {
        console.log("result==", result);
        if (typeof result.data === "string") {
          return result.data;
        } else {
          if (result.data && result.data.downloadUrl) {
            result.data.downloadUrl.indexOf("http://") > -1 &&
              (result.data.downloadUrl = result.data.downloadUrl.replace(
                "http://",
                "//"
              ));
            result.data.downloadUrl.indexOf("https://") > -1 &&
              (result.data.downloadUrl = result.data.downloadUrl.replace(
                "https://",
                "//"
              ));
          }

          return (result.data && result.data.downloadUrl) || "";
        }
      });
    },
    // 关闭预览弹框
    closePreviewDialog() {
      this.showPreviewDialog = false;
    },
    // 获取资源类型
    getResourceType(format) {
      if (["mp4", "MP4", "mov", "MOV"].indexOf(format) > -1) {
        return "video";
      } else if (
        [
          "mp3",
          "MP3",
          "wav",
          "WAV",
          "flac",
          "FLAC",
          "aac",
          "AAC",
          "m4a",
          "M4A",
        ].indexOf(format) > -1
      ) {
        return "audio";
      } else if (
        [
          "doc",
          "docx",
          "DOC",
          "DOCX",
          "ppt",
          "pptx",
          "PPT",
          "PPTX",
          "pdf",
          "PDF",
          "xls",
          "xlsx",
          "xlsx",
          "XLSX",
        ].indexOf(format) > -1
      ) {
        return "wpsFile";
      } else if (
        ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"].indexOf(format) > -1
      ) {
        return "image";
      } else {
        return "otherType";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.float-menu {
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  .no-file {
    text-align: center;
    color: #666666;
    font-size: 12px;
  }
  .switch-tips {
    position: absolute;
    right: 5px;
    &.switch_on_tips {
      right: 100px;
    }
    bottom: 15px;
    .tip-close {
      text-align: right;
      font-size: 0;
      img {
        cursor: pointer;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        margin-bottom: 8px;
      }
    }
    .tip-content {
      cursor: default;
      width: 224px;
      height: 48px;
      position: relative;
      .tip-bg {
        width: 224px;
        height: 48px;
      }
      .tip-text {
        position: absolute;
        top: 10px;
        display: flex;
        align-items: center;
        .tip-click-icon {
          width: 18px;
          height: 19px;
          margin-left: 16px;
        }
        .tip-text-content {
          margin-left: 8px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .file-list-box {
    padding: 10px 0;
    -webkit-box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
    box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
    height: 240px;
    box-sizing: border-box;
    overflow: auto;
  }
  .toolicon {
    width: 36px;
    height: 36px;
  }
  .menu-nav {
    width: 22px;
    height: 96px;
    background: #f6f7f9;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: calc(50% - 48px);
    left: -21px;
    cursor: pointer;
    i {
      width: 10px;
      vertical-align: middle;
      margin-top: 28px;
      margin-right: 0;
      margin-left: 6px;
    }
  }
  .menu-content {
    width: 88px;
    background: #f8f8f8;
    // border-radius: 10px;
    color: #333;
    padding: 18px 0 1px 0;
    box-shadow: 2px 5px 5px 0 rgba(85, 84, 103, 0.3);
    box-sizing: border-box;
    font-size: 14px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 26px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .icon-common () {
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: top;
    margin-right: 10px;
  }
  .icon-nav {
    .icon-common();
    .gen-icon(icon_teaching_open);
  }
  .icon-question {
    .icon-common();
    .gen-icon(icon_taach_pick);
    width: 36px;
    height: 36px;
    margin-right: 0;
  }
  .icon-call {
    .icon-common();
    .gen-icon(icon_taach_list);
    width: 42px;
    height: 42px;
    margin-right: 0;
  }
  .icon-painting {
    .icon-common();
    .gen-icon(icon_taach_draw);
    width: 36px;
    height: 36px;
    margin-right: 0;
  }
  .icon-clear {
    .icon-common();
    margin-right: 0;
    .gen-icon(painting_Eliminate);
  }
  .disabled {
    opacity: 0.2;
    cursor: no-drop;
  }
  .menu-file-list {
    width: 350px;
    height: 276px;
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    right: -350px;
    margin-top: -80px;
    overflow-y: auto;
    // padding:10px 0;
    /deep/ .el-tabs__nav-prev {
      left: 0;
      height: 36px;
      line-height: 36px;
    }
    /deep/ .el-tabs__nav-next {
      right: 0;
      height: 36px;
      line-height: 36px;
    }
    /deep/ .el-tabs__item {
      padding: 0 7px;
      height: 36px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 36px;
      display: inline-block;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      position: relative;
    }
    /deep/ .el-tabs__active-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: #5a53f5;
      z-index: 1;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }
    /deep/ .el-tabs__header {
      padding: 0;
      position: relative;
      margin: 0 0 0px;
    }
    .file-type-title {
      padding: 10px 14px;
      height: 14px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .file-item {
      display: flex;
      padding: 10px 14px;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
      .file-icon {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
      .file-name {
        font-size: 12px;
        color: #666666;
        word-break: break-word;
        word-wrap: break-word;
      }
      .file-name-active {
        font-size: 12px;
        color: #6049ff;
      }
    }
    .file-item-disabled {
      display: flex;
      padding: 10px 14px;
      cursor: not-allowed;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .menu-file-list::-webkit-scrollbar {
    display: none;
  }
}
.en-status {
  .float-menu .menu-content > div {
    font-size: 15px;
    text-align: center;
  }
}
.move_file_list {
  -webkit-animation-name: move_file_list;
  animation-name: move_file_list;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_file_list {
  -webkit-animation-name: move_on_file_list;
  animation-name: move_on_file_list;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.switch_tips {
  -webkit-animation-name: switch_tips;
  animation-name: switch_tips;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.switch_on_tips {
  -webkit-animation-name: switch_on_tips;
  animation-name: switch_on_tips;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.move_content {
  -webkit-animation-name: move_content;
  animation-name: move_content;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_nav {
  -webkit-animation-name: move_nav;
  animation-name: move_nav;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_content {
  -webkit-animation-name: move_on_content;
  animation-name: move_on_content;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_nav {
  -webkit-animation-name: move_on_nav;
  animation-name: move_on_nav;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes move_nav {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(98px);
  }
}
@keyframes move_nav {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@-webkit-keyframes move_file_list {
  from {
    opacity: 1;
  }
  to {
    right: 100px;
    // display: none;
  }
}
@keyframes move_file_list {
  from {
    opacity: 1;
  }
  to {
    right: 100px;
    // display: none;
  }
}
@-webkit-keyframes move_on_file_list {
  from {
    opacity: 1;
  }
  to {
    right: -350px;
    display: block;
  }
}
@keyframes move_on_file_list {
  from {
    opacity: 1;
  }
  to {
    right: -350px;
    display: block;
  }
}
@-webkit-keyframes move_on_nav {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes move_on_nav {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes move_on_content {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes move_on_content {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes move_content {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@keyframes move_content {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@-webkit-keyframes switch_on_tips {
  from {
    opacity: 1;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes switch_on_tips {
  from {
    opacity: 1;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes switch_tips {
  from {
    opacity: 1;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes switch_tips {
  from {
    opacity: 1;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
