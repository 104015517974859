<template>
  <!-- 授课页面 -->
  <div class="teaching mask">
    <!-- 选择课件、班级 -->
    <!-- <select-course
      ref="selectCourse"
      @select-back="selectBack"
      @cancel="handleClose"
      @change-classlist="changeClasslist"
      @onFullscreen="onFullscreen"
      :fullscreen="fullscreen"
      :courseId="courseId"
    ></select-course> -->
    <!-- <div class="teaching" :class="[!lessonParams.category || $route.query.fileType != 'kj' ? 'mask': 'mask-show']"> -->
    <template v-if="showKJ">
      <!-- <iframe ref="iframe" :src="file.resourceUrl | officePreviewUrl(file.format, false, true)" frameborder="0"></iframe> -->
      <div id="newwpsfile">
        <TeachingLession
          v-if="wpsSdkVersion === 1"
          ref="iframe"
          @changdio="changdio"
          v-bind:wpsUrl="wpsReadUrl"
          v-bind:token="wpsReadtoken"
          v-bind:wpscurryPage="wpscurryPage"
          @changewpscurryPage="changewpscurryPage"
          @onFullscreen="onFullscreen"
          @setinitdata="setinitdata"
          @setbuttonstate="setbuttonstate"
          @initPageAction="setTeachingPageAction"
        />
        <TeachingLessionV2
          v-if="wpsSdkVersion === 2"
          ref="iframe"
          @changdio="changdio"
          v-bind:wpsParams="wpsReadParams"
          v-bind:token="wpsReadtoken"
          v-bind:wpscurryPage="wpscurryPage"
          @changewpscurryPage="changewpscurryPage"
          @onFullscreen="onFullscreen"
          @setinitdata="setinitdata"
          @setbuttonstate="setbuttonstate"
          @initPageAction="setTeachingPageAction"
        />
      </div>
      <div v-if="!firstRender">
        <!-- <whiteboard
          v-show="fullscreen"
          ref="whiteboard"
          class="whiteboard"
          :status="status"
          @loaded="onLoaded"
        ></whiteboard> -->
        <whiteboard
          v-show="fullscreen"
          ref="whiteboard"
          class="whiteboard"
          :status="status"
          @loaded="onLoaded"
          :number="wpscurryPage"
        ></whiteboard>
        <whiteboard-tools
          v-drag
          v-show="fullscreen && status"
          ref="whiteboardTools"
          :status="status"
          @switch-status="onSwitchStatus"
          @clear="hanldeClear"
          :number="wpscurryPage"
        ></whiteboard-tools>
      </div>
      <div class="hide-edit"></div>
      <div class="whiteboard-exit">
        <div class="exit-screen" @click.stop="butonFullscreen">
          <img v-if="fullscreen" src="~@/assets/images/icon/exitview.png" />
          <img v-else src="~@/assets/images/icon/fullview.png" />
        </div>
        <div></div>
        <div class="exit-course" @click="exitVisible = true">
          <img src="~@/assets/images/icon/quitaccount.png" />
        </div>
      </div>
      <float-menu
        ref="floatMenu"
        :status="status"
        :fullscreen="fullscreen"
        @changewpscurryPage="changewpscurryPage"
        @switch-status="onSwitchStatus"
        @random-questions="onRandomQuestions"
        @call-roll="onCallRoll"
        @word-follow="onWordFollow"
        @hook:mounted="initFloatMenu"
        @selectBack="selectBack"
      ></float-menu>
      <div class="whiteboard-exit">
        <div class="exit-screen" @click.stop="butonFullscreen">
          <img v-if="fullscreen" src="~@/assets/images/icon/exitview.png" />
          <img v-else src="~@/assets/images/icon/fullview.png" />
          <!-- <span class="whiteboard-word" v-if="fullscreen">{{
            $t("el.teaching.exitFullScreen")
          }}</span>
          <span class="whiteboard-word" v-if="!fullscreen">{{
            $t("el.teaching.fullScreen")
          }}</span> -->
        </div>
        <div></div>
        <div class="exit-course" @click="exitVisible = true">
          <img src="~@/assets/images/icon/quitaccount.png" />
          <!-- <span class="whiteboard-course-word">{{
            $t("el.teaching.exitClass")
          }}</span> -->
        </div>
      </div>
      <div class="pages" v-if="showPPTpages">
        <div
          :class="wpscurryPage !== 1 ? 'page-back' : 'page-no-back'"
          @click="setfloorbutton"
        >
          <i class="el-icon-caret-left"></i>
        </div>
        <div class="page-numbers" @click="showPagePreview">
          {{ wpscurryPage }}/{{ wpscount }}
        </div>
        <div
          :class="wpscurryPage == wpscount ? 'page-no-next' : 'page-next'"
          @click="setbutton"
        >
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
      <!-- <div
        :class="{
          sksetfloorbutton: initbutton,
          fullfloorset: fullscreen == true,
        }"
        v-if="initbutton"
      >
        <div v-if="floorstate">
          <el-button @click="setfloorbutton" size="mini" type="primary">{{
            $t("el.common.pageUp")
          }}</el-button>
        </div>
        <div v-if="!floorstate">
          <el-button size="mini" type="primary" disabled>{{
            $t("el.common.pageUp")
          }}</el-button>
        </div>
      </div>
      <div
        :class="{
          sksetbutton: initbutton,
          fullset: fullscreen == true,
        }"
        v-if="initbutton"
      >
        <div v-if="nextstate">
          <el-button @click="setbutton" size="mini" type="primary">{{
            $t("el.common.pageDown")
          }}</el-button>
        </div>
        <div v-if="!nextstate">
          <el-button size="mini" type="primary" disabled>{{
            $t("el.common.pageDown")
          }}</el-button>
        </div>
      </div> -->
    </template>
    <!-- 提示创建班级 -->
    <el-dialog
      :title="$t('el.common.reminder')"
      width="464px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="createVisible"
    >
      <div class="el-dialog__content">
        {{ $t("el.teaching.noSelectClass") }}，{{ $t("el.common.noData") }}
        <!-- <a
          href="javascript:void 0"
          @click.prevent="openWindow('manage/classManage')"
          >{{ $t("el.teaching.gotoAdd") }}</a
        > -->
      </div>
      <span slot="footer">
        <el-button type="primary" @click="createVisible = false">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 提示暂无权限 -->
    <el-dialog
      :title="$t('el.common.reminder')"
      width="464px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="notAuthVisible"
    >
      <div class="el-dialog__content">
        {{ $t("el.teaching.classNoStudent") }}
        <!-- {{ !$store.state.user.isHiddenSign ? "，" : "" }} -->
        <!-- <a
          v-if="!$store.state.user.isHiddenSign"
          href="javascript:void 0"
          @click.prevent="
            currentClass.classType === '1'
              ? openWindow(
                  `manage/studentManage?id=${
                    (currentClass && currentClass.id) || ''
                  }&name=${(currentClass && currentClass.className) || ''}`
                )
              : ~~currentClass.classType === 2
              ? openWindow(
                  `manage/courseClassStudent?classId=${
                    (currentClass && currentClass.id) || ''
                  }&className=${
                    (currentClass && currentClass.className) || ''
                  }&courseId=${
                    (currentClass && currentClass.courseId) || ''
                  }&schoolCode=${
                    (currentClass && currentClass.schoolCode) || ''
                  }`
                )
              : openWindow(
                  `manage/classStudentManage?classId=${
                    (currentClass && currentClass.id) || ''
                  }&schoolCode=${
                    (currentClass && currentClass.schoolCode) || ''
                  }&gradeId=${(currentClass && currentClass.gradeId) || ''}`
                )
          "
          >{{ $t("el.teaching.add") }}</a
        > -->
      </div>
      <span slot="footer">
        <el-button type="primary" @click="notAuthVisible = false">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 提示确定退出 -->
    <el-dialog
      width="464px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="exitVisible"
    >
      <div slot="title" style="font-size: 20px; color: #646c95">
        {{ $t("el.teaching.confirmExit") }}
      </div>
      <div class="el-dialog__content">
        {{ $t("el.teaching.confirmExitSession") }}
      </div>
      <span slot="footer">
        <!-- 这里一个 -->
        <el-button
          v-if="!$store.state.user.isThirdSign"
          type="primary"
          @click="handleExit"
          style="float: left"
          >{{ $t("el.teaching.logout") }}</el-button
        >
        <el-button @click="cancelSk">{{ $t("el.common.cancel") }}</el-button>
        <el-button
          :style="$store.state.user.isHiddenSign ? 'float: initial;' : ''"
          type="primary"
          @click="handleClose"
          >{{ $t("el.teaching.exitSession") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 提示抽选 -->
    <!-- <el-dialog
      width="464px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="selectVisible"
      @close="handleSelected"
    >
      <div slot="title" class="el-dialog__header-icon">
        <img src="~@/assets/images/pic_selection.png" />
      </div>
      <div
        class="el-dialog__hint"
      >{{ (stuList[selectedStudent] && stuList[selectedStudent].fullName) || '' }}</div>
      <span slot="footer">
        <el-button type="primary" @click="handleSelected">{{ randomTimer ? '结束' : '重新抽选' }}</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      width="653px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="selectVisible"
      @close="handleSelected"
      class="el-dialog--stu-select-list"
    >
      <div class="el-dialog__call-over-img">
        <img src="~@/assets/images/pic_draw@2x.png" />
        <img
          class="call-over-draw"
          :class="[isNeedShine ? 'animation-draw' : '']"
          src="~@/assets/images/pic_draw_name@2x.png"
        />
      </div>
      <div ref="boxWrapper" class="box-wrapper animation-flash"></div>
      <el-scrollbar class="el-scrollbar_x" style="height: 110px">
        <transition-group tag="ul" class="ul-test" name="uls">
          <li
            v-for="(stu, index) in stuList"
            class="lis"
            v-show="callOverIndex === index"
            :key="index + stu.fullName"
            :title="stu.fullName"
          >
            <div class="stu-list__item">
              <div
                class="t-over2"
                :class="[
                  callOverIndex === index && isNeedShine
                    ? 'animation-words'
                    : '',
                ]"
              >
                {{ stu.fullName.slice(0, 5) }}
              </div>
            </div>
          </li>
        </transition-group>
      </el-scrollbar>
      <div class="call-over-operate">
        <img
          @mousedown="changeMouseDownStatus"
          @mouseup="changeMouseUpStatus"
          :src="callOverBtn"
        />
      </div>
    </el-dialog>

    <!-- 点名列表 -->
    <el-dialog
      :width="stuList.length ? '720px' : '400px'"
      @close="closeStudentName"
      :visible.sync="stuListVisible"
      class="el-dialog--stu-list"
    >
      <div slot="title">
        {{ $t("el.teaching.studentList") }}
      </div>
      <div class="stu-dialog-container">
        <p v-if="classType === '2' && stuList.length" class="tip">
          {{ $t("el.planCourse.attendanceNumber", attendanceNumber) }}
        </p>
        <el-scrollbar
          v-if="stuList.length"
          class="el-scrollbar_x"
          style="height: 370px"
        >
          <ul class="stu-list readonly">
            <li
              v-for="(stu, index) in stuList"
              :key="index"
              :title="stu.fullName"
              :class="{ rolled: stu.isCheck }"
            >
              {{ stu.fullName }}
              <span class="icon">
                <img src="@/assets/images/rolled.png" alt="" />
              </span>
            </li>
          </ul>
        </el-scrollbar>
        <div v-if="!stuList.length" class="no-data">
          {{ $t("el.teaching.classNoStudent") }}
        </div>
        <div
          v-if="classType === '2' && stuList.length && courseIsToday"
          class="stu-footer"
        >
          <el-button
            class="stu-confirm-btn"
            @click="handleOpenRollCallDialog"
            >{{ $t("el.planCourse.studentAttendance") }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 点名操作 -->
    <el-dialog
      width="720px"
      @close="closeRollCall"
      :visible.sync="rollCallVisible"
      class="el-dialog--stu-list"
    >
      <div slot="title">
        {{ $t("el.planCourse.studentAttendance") }}
        <span class="sub-title">{{
          $t("el.planCourse.rollCallCantCancel")
        }}</span>
      </div>
      <div class="stu-dialog-container">
        <div class="choose-all">
          <el-checkbox v-model="rollCallAll" @change="handleChooseChange">{{
            $t("el.common.SelectAll")
          }}</el-checkbox>
        </div>
        <el-scrollbar class="el-scrollbar_x" style="height: 370px">
          <ul class="stu-list">
            <li
              v-for="(stu, index) in stuList"
              :key="index"
              :title="stu.fullName"
              :class="{
                rolled: stu.isCheck,
                choose: rollCallChoose.includes(stu.studentId),
              }"
              @click="handleChooseToggle(stu)"
            >
              {{ stu.fullName }}
              <span class="icon">
                <img src="@/assets/images/rolled.png" alt="" />
              </span>
            </li>
          </ul>
        </el-scrollbar>
        <div class="stu-footer">
          <el-button @click="handleConfirmAttendance" class="stu-confirm-btn">{{
            $t("el.planCourse.confirmAttendance")
          }}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 单词跟读弹窗 -->
    <word-follow
      :wordData="wordData"
      :showWordFollowDialog="showWordFollow"
      @onWordFollowClose="onWordFollow"
    >
    </word-follow>
  </div>
</template>

<script>
/* eslint-disable */
import drag from "@/directive/drag/index";
import { mapState, mapActions } from "vuex";
import Whiteboard from "@/components/whiteboard.vue";
import WhiteboardTools from "@/components/whiteboard-tools.vue";
import TeachingLession from "./components/teachingLession.vue";
import WordFollow from "./components/wordFollowDialog.vue";
import TeachingLessionV2 from "./components/teachingLessionV2";
import FloatMenu from "./components/float-menu.vue";
import screenfull from "screenfull";
import SelectCourse from "@/components/select-course.vue";
import { MessageBox } from "element-ui";
import {
  queryKnowledgeInfo,
  checkResourceAuth,
  webofficeUrl,
  getWPSParam,
} from "@/api/classroom";
import {
  getClassStudentList,
  getClassStudentList2,
  getStartLesson,
  getKeepLesson,
  delPersonResource,
  getRollCallStudent,
  rollCall,
  getWordsList,
} from "@/api/teaching";
import { addDownloadLikeRecord, queryResource } from "@/api/classroom/index.js";
import { canvasSupport } from "@/utils/check-any.js";
import { getToken } from "@/core/auth";
import { handparams } from "../../utils";
import { initBrowserPrompt } from "../../utils/index.js";

import { checkBSKAuth } from "@/api/classroom/index";

let hasRemind = false;
let hasAlert = false;
export default {
  name: "teaching",
  components: {
    Whiteboard,
    WhiteboardTools,
    FloatMenu,
    SelectCourse,
    TeachingLession,
    TeachingLessionV2,
    WordFollow,
  },
  directives: {
    drag,
  },
  props: {},
  data() {
    return {
      /**
       * 文件数据
       */
      showPPTpages: true, // 判断是否为ppt显示右下角页码
      wpsSdkVersion: 2,
      isInnerIframe: false,
      resourceType: "KJ", // 资源类型
      ifameFullscreen: true, // 在 iframe中的全屏装填，默认为 true
      pagePreview: true, // 当前预览PPT的显示框状态
      fileData: {},
      file: null, // 当前课件文件
      fullscreen: false, // 是否全屏状态
      createVisible: false, // 提示创建班级
      notAuthVisible: false, // 提示暂无权限
      exitVisible: false, // 提示确定退出
      selectVisible: false, // 提示抽选
      stuListVisible: false, // 学生列表
      status: false, // 编辑状态
      w: null, // 白板对象
      firstRender: true, // 首次渲染
      firstShowTools: true, // 首次显示工具
      pointer: 0, // 指针
      count: 1, // 记录数
      loading: null, // 正在努力加载数据
      showKJ: false, // 显示课件
      knowledgeData: {}, // 知识点数据
      classlist: [], // 班级列表
      currentClass: null, // 当前班级
      knowledgeId: undefined, // 知识点id
      resourceId: undefined, // 资源id
      classId: "", // 班级id
      stuList: [], // 学生列表
      selectedStudent: 0, // 选中的学生
      isSelected: false, // 正在抽选中
      teachId: "", // 当前播放资源的teachId
      timer: null, // 保持视频播放
      openWindow: this.$utils.openWindow, // 打开窗口
      randomTimer: null, // 随机提问定时器
      lessonParams: {
        category: 0, // 自己的课件
      },
      callOverBtn: "",
      callOverIndex: 0,
      playCallInterval: null,
      playCallDelay: 100,
      isNeedShine: false,
      wpsReadUrl: "",
      wpsReadParams: {},
      wpsReadtoken: "",
      wpscount: "1", // 总页数
      wpscurryPage: 1, // 当前页码
      courseId: "",
      originTenantId: "",
      originTenantType: "",
      floorstate: false,
      nextstate: false,
      initbutton: false,
      hasTimeOut: false,
      monitorStatus: false, // 是否正在监控
      gradeId: "",
      classType: "",
      className: "",
      schoolCode: "",
      // boardlist:[],
      rollCallVisible: false, // 点名操作弹窗
      rollCallAll: false, // 是否全选
      rollCallChoose: [],
      attendanceCount: 0, //出勤人数
      absenceAttendanceCount: 0, // 缺勤人数
      importCourseId: "", // 排课id
      courseDate: "", // 课程的日期，如果当天和课程日期不相同则不能点名
      showWordFollow: false,
      wordData: [], //单词跟读数据
    };
  },
  computed: {
    ...mapState({
      cookieTeachId: (state) => state.teachId,
      documentHidden: (state) => state.documentHidden, // 当前窗口隐藏
      windowFocus: (state) => state.windowFocus, // 当前窗口是否获取焦点
    }),
    // 出勤与缺勤的人数
    attendanceNumber() {
      return [this.attendanceCount, this.absenceAttendanceCount];
    },
    // 课程时间是否是当天
    courseIsToday() {
      return this.courseDate === new Date().setHours(0, 0, 0, 0);
    },
  },
  watch: {
    // documentHidden: "changeDocumentHidden",
    windowFocus: "changeWindowFocus",
    stuListVisible: "changeBorderAnimation",
    // 监听已选择的值，改变全选按钮的勾选值
    rollCallChoose(v) {
      let checkLen = v.length;
      this.stuList.forEach((item) => {
        if (item.isCheck) {
          checkLen++;
        }
      });
      this.rollCallAll = checkLen === this.stuList.length;
    },
    // 初次进入弹窗时，判断是否需要全选
    stuList: {
      handler: function (v) {
        let checkLen = 0;
        v.forEach((item) => {
          if (item.isCheck) {
            checkLen++;
          }
        });
        this.rollCallAll = checkLen === this.stuList.length;
      },
      deep: true,
      immediate: true,
    },
    resourceId() {
      sessionStorage.removeItem("wordFollowData");
      sessionStorage.removeItem("wordFollowIndex");
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.originTenantId = this.$route.query.originTenantId;
    this.originTenantType = this.$route.query.originTenantType;
    this.courseTenantInfoId = this.$route.query.courseTenantInfoId;
    this.importCourseId = this.$route.query.importCourseId;
    this.isInnerIframe = window.self !== window.top;
    document.addEventListener("keydown", this.onF11FullScreen);
    window.addEventListener("resize", this.onResize);
    screenfull.on("change", this.onFullscreenChange);
    initBrowserPrompt();
  },
  mounted() {
    this.callOverBtn = require("@/assets/images/" +
      this.$t("el.teaching.startPng"));
    this.initPageActionListener();
    window.addEventListener("message", this.iframeInitPageAction);
    this.init();
  },
  destroyed() {
    sessionStorage.removeItem("wordFollowData");
    sessionStorage.removeItem("wordFollowIndex");
    document.removeEventListener("keyup", this.onF11FullScreen);
    window.removeEventListener("resize", this.onResize);
    screenfull.off("change", this.onFullscreenChange);
    this.removePageActionListener();
    window.removeEventListener("message", this.iframeInitPageAction);
  },
  methods: {
    async showPagePreview() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      app.ActivePresentation.SlideShowSettings.SetMiniThumbnailVisible(
        this.pagePreview
      );
    },
    /**
     * 取消授课
     */
    cancelSk() {
      this.exitVisible = false;
      document.querySelector("#newwpsfile iframe").focus();
    },
    /**
     * 改变wpscurryPage
     */
    changewpscurryPage(data, type) {
      if (type == 1) {
        this.wpscurryPage = data;
      }
      if (type == 2) {
        this.wpscount = data;
      }
    },
    changdio() {
      this.exitVisible = true;
    },
    /**
     * 获取WPS信息
     */
    getwpsInfo(type) {
      // if (!this.$route.query.resourceId) {
      //   return;
      // }
      let thiswpsstate = "read";
      if (this.wpsSdkVersion === 1) {
        webofficeUrl({
          _w_file_id: this.resourceId,
          _w_file_format: type.toLowerCase(),
          _w_permission: thiswpsstate,
          _w_file_version: this.version,
          _w_file_code: this.courseId + "-" + this.lessonParams.resourceType,
        }).then((res) => {
          this.wpsReadUrl = res.data.wpsUrl;
          this.wpsReadtoken = res.data.token;
          // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
        });
      } else {
        let params = {
          _w_file_code: this.courseId + "-" + this.lessonParams.resourceType,
          _w_file_format: type.toLowerCase(),
          _w_file_id: this.resourceId,
          _w_file_version: this.version,
          _w_isUpdate: 0,
        };
        getWPSParam(params).then((res) => {
          this.wpsReadParams = res.data;
          this.wpsReadtoken = getToken();
        });
      }
    },
    changeBorderAnimation() {
      this.$nextTick(() => {
        this.$refs.boxWrapper.style.display = "none";
        this.isNeedShine = false;
      });
    },
    /**
     * 关闭定时器
     */
    closeCallOver() {
      if (this.playCallInterval !== null) {
        this.changeBorderAnimation();
        clearInterval(this.playCallInterval);
        this.playCallInterval = null;
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.startPng"));
      }
    },
    /**
     * 定时任务
     */
    playCall() {
      this.callOverIndex++;
      if (this.callOverIndex === this.stuList.length) {
        this.callOverIndex = 0;
      }
    },
    playCallBack() {
      this.playCallInterval = setInterval(this.playCall, this.playCallDelay);
    },
    /**
     * 点名-鼠标按压下，未松开
     */
    changeMouseDownStatus() {
      if (this.callOverBtn.indexOf("pic_button_start") > -1) {
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.startPressPng"));
      } else if (this.callOverBtn.indexOf("pic_button_suspend") > -1) {
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.pausePressPng"));
      }
    },
    /**
     * 初始化状态
     */
    setinitdata(type) {
      this.initbutton = type;
    },
    /**
     * 改变按钮状态
     */
    setbuttonstate(type1, type2) {
      this.floorstate = type1;
      this.nextstate = type2;
    },
    /**
     * 上一页
     */
    async setfloorbutton() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.nextstate = true;
      if (wpscurryPage == 1) {
        this.floorstate = false;
      }
      if (wpscurryPage > 1) {
        if (wpscurryPage == 2) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = true;
        }
      }
    },
    /**
     * 下一页
     */
    async setbutton() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.floorstate = true;
      if (count == wpscurryPage) {
        this.nextstate = false;
      }
      if (count > wpscurryPage) {
        if (count == wpscurryPage + 1) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = true;
        }
      }
    },
    /**
     * 点名-鼠标松开
     */
    changeMouseUpStatus() {
      if (this.callOverBtn.indexOf("pic_button_start_active") > -1) {
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.pausePng"));
        this.changeBorderAnimation();
        this.playCallBack();
      } else if (this.callOverBtn.indexOf("pic_button_suspend_active") > -1) {
        this.$nextTick(() => {
          this.$refs.boxWrapper.style.display = "block";
          this.isNeedShine = true;
        });
        clearInterval(this.playCallInterval);
        this.playCallInterval = null;
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.startPng"));
      }
    },
    /**
     * 初始化
     */
    init() {
      this.initRefreshMonitor();
      this.initLessionInfor(); // 去授课
    },
    /**
     * 初始化刷新监听
     */
    initRefreshMonitor() {
      // 页面刷新
      window.addEventListener("beforeunload", function () {
        this.endMonitor(false);
      });
      // 销毁时间
      this.$on("hook:destroyed", function () {
        window.removeEventListener("beforeunload", function () {
          console.log("销毁时间");
          this.endMonitor(false);
        });
      });
    },
    /**
     * 开始授课
     */
    startTeaching() {
      this.startMonitor();
    },

    /**
     *
     * 授课页面失去焦点 5分钟 结束方法
     */
    setTeachingPageFocusAction(isFocus) {
      if (!this.hasTimeOut) {
        if (this.monitorStatus) {
          // 是否在监控
          if (this.teachingTimeOutAction) {
            clearTimeout(this.teachingTimeOutAction);
          }
          this.teachingTimeOutAction = setTimeout(() => {
            this.hasTimeOut = true;
            console.log("授课页面失去焦点 5分钟 结束方法");
            this.endMonitor(false);
          }, Number.parseInt(isFocus ? process.env.VUE_APP_PAGE_REFRESH_TIME : process.env.VUE_APP_PAGE_HIDDEN_REFRESH_TIME) * 60 * 1000);
        }
      }
    },

    // 接收 iframe 内 wps 动作 消息，调用 记录授课数据 方法
    iframeInitPageAction(message) {
      let data =
        message.data &&
        Object.prototype.toString.call(message.data) !== "[object Object]" &&
        JSON.parse(message.data);
      if (
        data.type === "AIYKT" &&
        data.data &&
        data.data.type === "TEACHING_PAGE_ACTION"
      ) {
        if (data.data.refresh) {
          this.setTeachingPageAction();
        }
      }
    },

    /**
     *
     * 初始化 授课 20分钟 结束方法
     */
    setTeachingPageAction() {
      if (this.hasTimeOut) {
        this.hasTimeOut = false;
        this.startTeaching();
      }
      if (this.monitorStatus) {
        // 是否在监控
        if (this.teachingTimeOutAction) {
          clearTimeout(this.teachingTimeOutAction);
        }
        this.teachingTimeOutAction = setTimeout(() => {
          this.hasTimeOut = true;
          console.log("初始化 授课 20分钟 结束方法");
          this.endMonitor(false);
        }, Number.parseInt(process.env.VUE_APP_PAGE_REFRESH_TIME) * 60 * 1000);
      }
    },

    /**
     * 设置页面动作监听
     */
    initPageActionListener() {
      document.body.addEventListener("keyup", this.setTeachingPageAction);
      document.body.addEventListener("mousemove", this.setTeachingPageAction);
      document.body.addEventListener("touchstart", this.setTeachingPageAction);
      document.body.addEventListener("scroll", this.setTeachingPageAction);
      // let wpsIframe;
      // let iframeElement = await this.getWpsIframe(wpsIframe);
      // iframeElement = this.wpsConfig.iframe;
    },
    // 移除监听
    removePageActionListener() {
      document.body.removeEventListener("keyup", this.setTeachingPageAction);
      document.body.removeEventListener(
        "mousemove",
        this.setTeachingPageAction
      );
      document.body.removeEventListener(
        "touchstart",
        this.setTeachingPageAction
      );
      document.body.removeEventListener("scroll", this.setTeachingPageAction);
    },

    /**
     * 结束授课
     * @param {*} sync 同步请求，默认：同步
     */
    // endTeaching (sync) {
    //   this.endMonitor(sync);
    // },
    /**
     * 开始监控
     */
    startMonitor() {
      if (hasAlert) return;
      if (this.teachId) {
        // 目前正在监控
        console.log("开始监控");
        this.endMonitor(false); // 停止上一次监控
      }
      if (!(this.lessonParams && this.lessonParams.resourceId)) {
        console.log("未加载资源信息");
        return;
      }
      console.log("开始监控");
      this.monitorStatus = true;
      this.hasTimeOut = false; // 开始监控 不调用 startTeach
      this.setTeachingPageAction();
      // 发起监控请求
      getStartLesson(this.lessonParams)
        .then(({ data }) => {
          hasRemind = false;
          this.teachId = data?.id || data; // 缓存监控id
          this.keepMonitor(); // 启动循环监控保持
          if (data?.remind && !hasRemind) {
            this.$message.warning(
              "由于课程设置，当前时段的课程可用时间已不足10分钟，到时将自动结束，请尽快完成备授课"
            );
            hasRemind = true;
          }
        })
        .catch((err) => {
          console.log("startLessonErr", err);
          if (err.message.includes("时段")) {
            if (this.fullscreen) {
              this.onFullscreen();
            }
            this.$router.replace({
              name: "Auth",
              query: {
                title: "课程非可用时段",
              },
            });
          }
        });
    },
    /**
     * 结束监控
     * @param {*} sync 同步请求，默认：同步
     */
    endMonitor(sync = true) {
      // if (!this.teachId) throw new Error('监控id不存在，无法结束监控');
      this.hasTimeOut = true;
      if (!this.teachId) {
        console.warn("监控id不存在，无法结束监控");
        return;
      }
      try {
        let token = getToken(); // 获取token
        let sendData = {
          // 传递的参数
          teachId: this.teachId,
        };
        console.log("sync", sync);

        // 结束监控设置数据
        clearInterval(this.timer); // 清楚保持监控循环
        this.monitorStatus = false;
        // this.teachId = ""; // 清楚监控id
        this.timer = null;

        if (sync) {
          // 发起同步请求
          let url =
            "/aiykt-gateway/data-point/teachManage/finishLesson?" +
            new URLSearchParams(sendData).toString();
          if (navigator.sendBeacon) {
            //
            let blob = new Blob([JSON.stringify(sendData)], {
              type: "application/json",
            });
            navigator.sendBeacon(url, blob);
          } else {
            let xhr = new XMLHttpRequest();
            xhr.open("post", url, false);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Authorization", token);
            xhr.send();
          }
        } else {
          // 发起异步请求
          return delPersonResource(sendData);
        }
      } catch (e) {
        throw e;
      } finally {
        // 结束监控设置数据
        clearInterval(this.timer); // 清楚保持监控循环
        this.monitorStatus = false;
        this.teachId = ""; // 清楚监控id
        this.timer = null;
        console.log("监控结束");
      }
    },
    /**
     * 保持监控
     */
    keepMonitor() {
      // if (!this.teachId) throw new Error('监控id不存在，无法保持监控');
      if (!this.teachId) {
        console.warn("监控id不存在，无法保持监控");
        return;
      }
      let teachId = this.teachId; // 缓存监控id
      if (this.timer) clearInterval(this.timer); // 如果有定时器任务，先清除定时器
      this.timer = setInterval(() => {
        getKeepLesson({
          teachId,
        })
          .then((res) => {
            if (res.data.out) {
              this.endMonitor(false);
              hasAlert = true;
              this.$alert("课程当前可用时段已结束，已自动结束备授课", "提示", {
                center: true,
                type: "warning",
                confirmButtonText: "我知道了",
              }).finally(() => {
                this.handleClose();
              });
            } else if (res.data.remind && !hasRemind) {
              this.$message.warning(
                "由于课程设置，当前时段的课程可用时间已不足10分钟，到时将自动结束，请尽快完成备授课"
              );
              hasRemind = true;
            }
          })
          .catch(({ message }) => {
            if (message === this.$t("el.teaching.sessionFailQuestion")) {
              // 结束授课
              clearInterval(this.timer);
              this.teachId = "";
              this.timer = null;
              this.startTeaching();
            }
          });
      }, 1000 * 60 * 2);
    },
    // startTeaching () {
    //   let args = [].slice.call(arguments, 0);
    //   // if (!this.windowFocus) return false;  // 未获取焦点，不开始监控
    //   if (this.documentHidden) return false;  // 窗口隐藏，不开始监控
    //   if (!this.resourceId) return false; // 无资源id
    //   if (!(this.lessonParams &&
    //     this.lessonParams.resourceId)) return false; // 未选择
    //   if (!args.length) { // 无参数
    //     args[0] = this.lessonParams;
    //     args[1] = this.resourceId;
    //   }
    //   this.getLesson.apply(this, args); // 开始授课
    // },
    /**
     * 结束授课
     * @param {*} sync 同步请求
     */
    // endTeaching (sync = true) {
    //   let teachIds = [];
    //   if (!Array.isArray(this.cookieTeachId)) {
    //     teachIds = JSON.parse(this.cookieTeachId);
    //   } else {
    //     teachIds = this.cookieTeachId;
    //   }
    //   teachIds.forEach((item, index) => {
    //     for (let key in item) {
    //       if (key == this.resourceId) {
    //         this.teachId = item[key];
    //       }
    //     }
    //   });
    //   // 无授课记录
    //   if (!this.teachId) return true;
    //   let token = getToken();
    //   let sendData = {
    //     teachId: this.teachId
    //   };
    //   if (sync) {  // 同步
    //     let url = '/data-point/teachManage/finishLesson?' + new URLSearchParams(sendData).toString();
    //     if (navigator.sendBeacon) { // 是否发送异步请求
    //       let blob = new Blob([], {
    //         type: 'application/json'
    //       });
    //       navigator.sendBeacon(url, blob);
    //     } else {
    //       let xhr = new XMLHttpRequest();
    //       xhr.open('post', url, false);
    //       xhr.setRequestHeader('Content-Type', 'application/json');
    //       xhr.setRequestHeader('Authorization', token);
    //       xhr.send();
    //     }
    //     this.stopMonitor();
    //   } else {
    //     delPersonResource({ teachId: this.teachId })
    //       .then(res => {
    //         this.stopMonitor();
    //       })
    //   }
    //   return false;
    // },
    /**
     * 停止监控
     */
    // stopMonitor () {
    //   this.$store.commit('delTeachId', this.resourceId);
    //   clearInterval(this.timer);
    //   this.teachId = '';
    //   console.log('结束监控');
    // },
    /**
     */
    // changeDocumentHidden() {
    //   console.log(
    //     this.documentHidden
    //       ? this.$t("el.teaching.hideWindow")
    //       : this.$t("el.teaching.showWindow")
    //   );
    //   this.documentHidden
    //     ? this.endMonitor(false) // 页面隐藏，结束监控
    //     : this.startTeaching(); // 页面打开，继续监控或者开始监控
    //   // if (!this.documentHidden) {
    //   //   // 已获取焦点
    //   //   if (isStartMonitor) { // 已开始监控
    //   //     // 不做操作
    //   //   } else {
    //   //     // 开始监控
    //   //     this.startTeaching();
    //   //   }
    //   // } else {
    //   //   // 未获取焦点
    //   //   if (isStartMonitor) { // 已开始监控
    //   //     // 停止监控
    //   //     this.endTeaching(false);
    //   //   } else {
    //   //     // 不做操作
    //   //   }
    //   // }
    // },
    /**
     * 监控应用焦点
     */
    changeWindowFocus() {
      console.log(this.windowFocus ? "获取焦点" : "失去焦点");
      let isStartMonitor = !!this.teachId;
      if (this.windowFocus) {
        // 已获取焦点
        if (isStartMonitor) {
          // 已开始监控
          // 不做操作
          this.setTeachingPageFocusAction(true);
        } else {
          // 开始监控
          // this.startTeaching();
        }
      } else {
        // 未获取焦点
        if (isStartMonitor) {
          // 已开始监控
          // 停止监控
          // this.endTeaching(false);
          this.setTeachingPageFocusAction(false);
        } else {
          // 不做操作
        }
      }
    },
    /**
     * 选择班级课件回调
     */
    async selectBack(data, come) {
      // if(come===1){
      //   data = Object.assign(this.fileData,data)
      //   console.log(data)
      // }else{
      //   this.fileData=data
      //   console.log(this.fileData)
      // }
      this.fileData = Object.assign(this.fileData, data);
      data = Object.assign(this.fileData, data);
      let resourceId = data.resourceId;
      // this.resourceId = resourceId;
      this.version = data.version;
      this.loading = this.$loading({
        text: this.$t("el.teaching.loadingData"),
      });
      var query = this.$route.query;
      if (query.resourceId != data.resourceId || query.classId != data.classId)
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            resourceId: data.resourceId,
            classId: data.classId,
            format: data.format,
            version: data.version,
          },
        });
      this.currentClass = data;
      let lessonParams = {
        schoolName: this.$store.state.user.schoolName, // 学校名称
        knowledgeId: query.id, // 知识点id
        resourceName: data.resource.resourceName, // 课件文件名
        category: data.resource.category, // 课件来源
        staffName: this.$store.state.user.staffName, // 员工姓名
        resourceId: data.resource.resourceId, // 资源id
        // staffCode: this.$store.state.user.staffCode,  // 员工工号
        resourceType: data.resource.resourceType || "KJ", // 默认课件资源，授课只能是课件
        // 授课页面切换知识点的时候的数据
        gradeId: this.gradeId,
        classType: this.classType,
        classId: this.classId,
        className: this.className,
        schoolCode: this.schoolCode,

        courseId: this.courseId,
        originTenantId: this.originTenantId,
        originTenantType: this.originTenantType,
        courseTenantInfoId: this.courseTenantInfoId,
      };
      this.lessonParams = lessonParams;
      if (come !== 1) {
        this.resourceId = resourceId;
      }
      if (!this.file || this.resourceId != data.resourceId) {
        this.resourceId = resourceId;
        this.getwpsInfo(data.format);
        addDownloadLikeRecord(data.resourceId, 2); // 统计次数
        if (come !== 1) {
          this.onFullscreen();
        }

        let checkCourseAuth = await this.toCheckBSKAuth();

        if (checkCourseAuth) {
          // 未选择、加载数据
          checkResourceAuth({
            resourceId: data.resourceId,
            courseId: this.knowledgeData.courseId || this.courseId,
            knowledgeId: this.knowledgeId,
            originTenantId: this.originTenantId,
          })
            .then(({ data }) => {
              // 检测文件类型，仅支持ppt和word文件
              // 现在增加了可以支持pdf文件授课
              if (
                ["doc", "docx", "ppt", "pptx", "pdf", "PDF"].indexOf(
                  data.format
                ) === -1
              ) {
                this.$alert(
                  this.$t("el.teaching.resourceUnsupport"),
                  this.$t("el.common.reminder"),
                  {
                    center: true,
                    type: "warning",
                    confirmButtonText: this.$t("el.common.backHomePage"),
                  }
                ).then(() => {
                  this.$router.push("/");
                });
                return;
              }
              if (data.auth === true) {
                if (
                  ["ppt", "pptx", "PPT", "PPTX"].indexOf(data.format) !== -1
                ) {
                  this.showPPTpages = true;
                } else {
                  this.showPPTpages = false;
                }
                this.showKJ = true;
                this.file = data;
                this.startTeaching();
              } else {
                this.$router.push({
                  name: "Resource",
                });
              }
            })
            .finally(() => {
              this.loading && this.loading.close();
            });
        }
      } else {
        this.startTeaching();
      }
    },
    // 要去掉 currentClass
    async initLessionInfor() {
      let lessonParams = {
        schoolName: this.$store.state.user.schoolName, // 学校名称
        knowledgeId: this.knowledgeId, // 知识点id
        resourceName: this.resourceName, // 课件文件名
        category: this.resourceCategory, // 课件来源
        staffName: this.$store.state.user.staffName, // 员工姓名
        resourceId: this.resourceId, // 资源id
        // staffCode: this.$store.state.user.staffCode,  // 员工工号
        resourceType: "KJ", // 默认课件资源，授课只能是课件
        gradeId: this.gradeId,
        classType: this.classType,
        classId: this.classId,
        className: this.className,
        schoolCode: this.schoolCode,

        courseId: this.courseId,
        originTenantId: this.originTenantId,
        originTenantType: this.originTenantType,
        courseTenantInfoId: this.courseTenantInfoId,
      };
      this.currentClass = this.classId
        ? {
            id: this.classId,
            className: this.className,
            classType: this.classType,
            courseId: this.knowledgeData.courseId,
            schoolCode: this.schoolCode,
            gradeId: this.gradeId,
          }
        : null;
      this.lessonParams = handparams(lessonParams);

      // this.resourceId = resourceId;
      this.getwpsInfo(this.format);
      addDownloadLikeRecord(this.resourceId, 2); // 统计次数
      if (this.come !== 1) {
        this.onFullscreen();
      }

      let checkCourseAuth = await this.toCheckBSKAuth();
      if (checkCourseAuth) {
        // 未选择、加载数据
        checkResourceAuth({
          resourceId: this.resourceId,
          courseId: this.knowledgeData.courseId || this.courseId,
          knowledgeId: this.knowledgeId,
          originTenantId: this.originTenantId,
        })
          .then(({ data }) => {
            // 检测文件类型，仅支持ppt和word文件
            // 现在增加了可以支持pdf文件授课
            if (
              ["doc", "docx", "ppt", "pptx", "pdf", "PDF"].indexOf(
                data.format
              ) === -1
            ) {
              this.$alert(
                this.$t("el.teaching.resourceUnsupport"),
                this.$t("el.common.reminder"),
                {
                  center: true,
                  type: "warning",
                  confirmButtonText: this.$t("el.common.backHomePage"),
                }
              ).then(() => {
                this.$router.push("/");
              });
              return;
            }
            if (data.auth === true) {
              if (["ppt", "pptx", "PPT", "PPTX"].indexOf(data.format) !== -1) {
                this.showPPTpages = true;
              } else {
                this.showPPTpages = false;
              }
              this.showKJ = true;
              this.file = data;
              this.startTeaching();
            } else {
              this.$router.push({
                name: "Resource",
              });
            }
          })
          .finally(() => {
            this.loading && this.loading.close();
          });
      }
    },
    async toCheckBSKAuth() {
      try {
        // 先校验课程权限
        const response = await checkBSKAuth({
          courseId: this.knowledgeData.courseId || this.courseId,
          originTenantId: this.originTenantId,
        });
        if (response && response.data) {
          return true;
        } else {
          this.$router.push({
            name: "Auth",
          });
          return false;
        }
      } catch (error) {
        this.$router.push({
          name: "Auth",
        });
        return false;
      }
    },
    /**
     * 窗口变化
     */
    onResize() {
      if (!this.showKJ) return;
      // this.initFloatMenu();
      this.$nextTick(() => {
        // 检查浮动菜单是否溢出
        var $floatMenu = this.$refs.floatMenu.$el;
        var $whiteboard = document.documentElement;
        var maxTop =
          $whiteboard.clientHeight / 2 -
          $floatMenu.clientHeight / 2 -
          120 +
          "px";
        var $floatMenu = this.$refs.floatMenu.$el;
        var $whiteboard = document.documentElement;
        $floatMenu.style.top =
          $whiteboard.clientHeight / 2 -
          $floatMenu.clientHeight / 2 -
          120 +
          "px";
        var left = $whiteboard.clientWidth - 88;
        if (left < 0) left = 0;
        $floatMenu.style.left = left + "px";
        $floatMenu.style.width = "0px";
        var maxLeft = $whiteboard.clientWidth - $floatMenu.clientWidth - 10;
        // this.initFloatMenu();
        // if (parseInt($floatMenu.style.top) > maxTop)
        //   $floatMenu.style.top = maxTop + "px";
        // if (parseInt($floatMenu.style.left) > maxLeft)
        //   $floatMenu.style.left = maxLeft + "px";
      });
    },
    /**
     * 自动全屏
     */
    onFullscreen() {
      if (this.fullscreen && !this.firstRender && this.status) {
        this.$refs.whiteboardTools.visible = false;
      }
      if (screenfull.isEnabled) {
        // let element =  document.documentElement;
        // 是否允许全屏
        screenfull.toggle();
      } else {
        if (this.isInnerIframe) {
          window.parent.postMessage(
            JSON.stringify({
              type: "AIYKT",
              data: {
                type: "FULL_SCREEN_TEACHING",
                fullScreen: !this.fullscreen,
              },
            }),
            "*"
          );
          this.ifameFullscreen = !this.fullscreen;
          this.fullscreen = !this.fullscreen;

          this.firstRender = false;
        } else {
          this.$message.warning(this.$t("el.teaching.browserUnfullsreen"));
        }
      }
    },
    /**
     * 按钮全屏
     */
    butonFullscreen() {
      if (this.fullscreen && !this.firstRender && this.status) {
        this.$refs.whiteboardTools.visible = false;
      }
      if (screenfull.isEnabled) {
        // 是否允许全屏
        screenfull.toggle();
        document.querySelector("#newwpsfile iframe").focus();
      } else {
        if (this.isInnerIframe) {
          this.ifameFullscreen = !this.ifameFullscreen;
          window.parent.postMessage(
            JSON.stringify({
              type: "AIYKT",
              data: {
                type: "FULL_SCREEN_TEACHING",
                fullScreen: this.ifameFullscreen,
              },
            }),
            "*"
          );
        } else {
          this.$message.warning(this.$t("el.teaching.browserUnfullsreen"));
          document.querySelector("#newwpsfile iframe").focus();
        }
      }
    },
    /**
     * 全屏状态改变
     */
    onFullscreenChange() {
      this.fullscreen = screenfull.isFullscreen;
      if (!this.firstRender && this.fullscreen) {
        setTimeout(() => {
          this.w._loadDataUrl(); // 退出全屏绘制记录会消失，重新全屏还原绘制。
        }, 0);
      }
      if (this.firstRender && this.fullscreen) this.firstRender = false;
      this.onResize(); // 重置浮动菜单位置
    },
    /**
     * 切换编辑状态
     */
    onSwitchStatus() {
      var broswer = this.$utils.getBroswer();
      var isIe = broswer.versions.trident; // 是否是ie浏览器
      var notCanvas = !canvasSupport(); // 是否支持canvas;
      if (isIe || notCanvas)
        return this.$alert("", this.$t("el.teaching.chooseBrowser"), {
          center: true,
          customClass: "el-message-box--confirm el-message-box--danger",
          message: this.$createElement(
            "div",
            {
              class: "",
            },
            this.$t("el.teaching.pleaseChangeBrowser")
          ),
          showConfirmButton: false,
        });
      this.status = !this.status;
      this.$refs.whiteboardTools.visible = false;
      this.status &&
        this.firstShowTools &&
        this.$nextTick(() => {
          this.initTools();
          this.firstShowTools = false;
        });
      if (this.status) {
        // 新需求（2020-05-28）：开启绘制默认选中笔
        this.$nextTick(() => {
          this.w && this.w.changeTool("pen");
          this.$refs.whiteboardTools.displaytips = true;
        });
      } else {
        // 关闭
        // 问题优化：结束绘制无法没有点击无法用遥控笔翻页
        // this.$refs.iframe.focus();  // 获取焦点
        document.querySelector("#newwpsfile iframe").focus();
        this.$refs.whiteboardTools.displaytips = false;
      }
    },
    /**
     * 关闭学生名单弹框
     */
    closeStudentName() {
      this.stuListVisible = false;
      document.querySelector("#newwpsfile iframe").focus();
    },
    /**
     * 加载完成
     */
    onLoaded(w) {
      this.w = w;
      this.$refs.floatMenu.init(w);
      this.initTools();
    },
    /**
     * 初始化工具
     */
    initTools() {
      // 初始化工具位置
      var tools = this.$refs.whiteboardTools;
      var $tools = tools.$el;
      var $whiteboard = document.documentElement;
      //   $tools.style.top =
      //     $whiteboard.clientHeight - $tools.clientHeight - 44 + "px";
      //   var left = $whiteboard.clientWidth / 2 - $tools.clientWidth / 2;
      //   if (left < 0) left = 0;
      //   $tools.style.left = left + "px";
      tools.init(this.w);
      // 初始化时，默认选中画笔
      this.$nextTick(() => {
        this.w && this.w.changeTool("pen");
        this.$refs.whiteboardTools.displaytips = true;
      });
    },
    /**
     * 初始化浮动菜单
     */
    initFloatMenu() {
      var $floatMenu = this.$refs.floatMenu.$el;
      var $whiteboard = document.documentElement;
      $floatMenu.style.top =
        $whiteboard.clientHeight / 2 - $floatMenu.clientHeight / 2 - 120 + "px";
      var left = $whiteboard.clientWidth - $floatMenu.clientWidth;
      if (left < 0) left = 0;
      $floatMenu.style.left = left + "px";
      $floatMenu.style.width = "0px";
    },
    /**
     * 检测按f11全屏
     */
    onF11FullScreen(e) {
      if (e && e.keyCode == 122) {
        // 捕捉F11键盘动作
        e.preventDefault(); // 阻止F11默认动作
      }
    },
    /**
     * 加载班级 classType 自建班级1  课程班级2  行政班级（授权）3
     */
    loadClass() {
      return new Promise((resolve, reject) => {
        if (!this.currentClass) {
          // 未选择班级
          this.createVisible = true;
          reject();
        } else {
          if (this.currentClass.classType === "1") {
            // 自建班级1
            getClassStudentList({
              classId: this.currentClass.id,
            })
              .then(({ data }) => {
                this.stuList = Array.isArray(data) ? data : [];
                if (this.stuList.length) {
                  resolve(data);
                } else {
                  reject();
                  this.notAuthVisible = true;
                }
              })
              .catch(reject);
          } else if (this.currentClass.classType === "3") {
            // 行政班3
            getClassStudentList2({
              id: this.currentClass.id,
              classType: this.currentClass.classType,
            })
              .then(({ data }) => {
                this.stuList = Array.isArray(data) ? data : [];
                if (this.stuList.length) {
                  resolve(data);
                } else {
                  reject();
                  this.notAuthVisible = true;
                }
              })
              .catch(reject);
          } else {
            // 课程班2
            getRollCallStudent(this.importCourseId, this.currentClass.id).then(
              ({ data }) => {
                this.attendanceCount = data.attendanceCount;
                this.absenceAttendanceCount = data.absenceAttendanceCount;
                this.courseDate = data.courseDate;
                this.stuList = (data.studentLists || []).map((item) => ({
                  ...item,
                  fullName: item.studentName,
                  isCheck: item.status === "0", // 0已点名不可再次勾选 1未点名可勾选
                }));
                if (this.stuList.length) {
                  resolve();
                } else {
                  reject();
                  this.notAuthVisible = true;
                }
              }
            );
          }
        }
      });
    },
    /**
     * 随机提问
     */
    onRandomQuestions() {
      this.loadClass().then(() => {
        this.handleSelected();
        this.selectVisible = true;
      });
    },
    /**
     * 点名
     */
    onCallRoll() {
      this.loadClass().then(() => {
        this.stuListVisible = true;
      });
    },
    /**
     * 班级列表更新
     * @param {Array} list 班级列表
     */
    changeClasslist(list) {
      this.classlist = list;
    },
    /**
     * 结束授课数据埋点
     */
    // getDelTeachId() {
    //   let teachIds = [];
    //   if (!Array.isArray(this.cookieTeachId)) {
    //     teachIds = JSON.parse(this.cookieTeachId);
    //   } else {
    //     teachIds = this.cookieTeachId;
    //   }
    //   teachIds.forEach((item, index) => {
    //     for (let key in item) {
    //       if (key == this.resourceId) {
    //         this.teachId = item[key];
    //       }
    //     }
    //   });
    //   if (this.teachId) {
    //     delPersonResource({ teachId: this.teachId })
    //       .then(res => {
    //         this.$store.commit("delTeachId", this.resourceId);
    //         clearInterval(this.timer);
    //       })
    //       .catch();
    //   }
    // },
    /**
     * 退出登录
     */
    async handleExit() {
      // this.getDelTeachId();
      // 结束监控
      if (this.fullscreen) {
        this.onFullscreen();
      }
      console.log("退出登录");
      await this.endMonitor(false);
      this.$nextTick(() => {
        this.$store.dispatch("logout").then(() => {
          this.fullscreen && this.onFullscreen();
          this.$router.push("/login");
        });
      });
    },
    /**
     * 结束授课
     */
    handleClose() {
      if (this.fullscreen) {
        this.onFullscreen();
      }
      console.log("结束授课");
      this.endMonitor(false); // 结束监控
      let from = this.$route.query.from;
      if (from === "home") {
        this.$router.push("/home");
      } else if (from === "schoolTimetabelCheck") {
        this.$router.go(-1);
      }
      //  else if (from == "bk") {
      //   this.$router.push({
      //     path: "/classroomWps/bk-kj",
      //     query: {
      //       id: this.$route.query.id,
      //       resourceId: this.$route.query.resourceId,
      //     },
      //   });
      // }
      else {
        this.$router.go(-1);
      }
      this.fetchQrCode({ source: "finishTeach" }); //finishReady、finishTeach
    },
    ...mapActions(["fetchQrCode"]),
    /**
     * 抽选学生
     */
    handleSelected() {
      if (this.playCallInterval !== null) {
        this.changeBorderAnimation();
        clearInterval(this.playCallInterval);
        this.playCallInterval = null;
        this.callOverBtn = require("@/assets/images/" +
          this.$t("el.teaching.startPng"));
      }
      document.querySelector("#newwpsfile iframe").focus();
    },
    /**
     * 清除本页
     * @param {number} index 索引
     */
    hanldeClear(index) {},

    // 勾选点名
    handleChooseToggle(item) {
      if (item.isCheck) {
        return;
      } else if (this.rollCallChoose.includes(item.studentId)) {
        const i = this.rollCallChoose.findIndex(
          (sub) => sub.studentId === item.studentId
        );
        this.rollCallChoose.splice(i, 1);
      } else {
        this.rollCallChoose.push(item.studentId);
      }
    },
    // 全选按钮点击事件
    handleChooseChange(v) {
      if (v) {
        this.stuList.forEach((item) => {
          if (!item.isCheck) {
            this.rollCallChoose.push(item.studentId);
          }
        });
        this.rollCallChoose = [...new Set(this.rollCallChoose)];
      } else {
        this.rollCallChoose.splice(0);
      }
    },
    // 打开点名弹窗
    handleOpenRollCallDialog() {
      this.stuListVisible = false;
      this.rollCallVisible = true;
    },
    // 确认出勤点名
    handleConfirmAttendance() {
      if (this.rollCallChoose.length) {
        rollCall({
          importCourseId: this.importCourseId,
          courseId: this.courseId,
          classId: this.classId,
          ids: [
            ...new Set([
              ...this.stuList
                .filter((item) => item.status === "0")
                .map((item) => item.studentId),
              ...this.rollCallChoose,
            ]),
          ],
        }).then(() => {
          this.$message.success(this.$t("el.planCourse.rollCallSuccess"));
          this.closeRollCall();
        });
      } else {
        this.closeRollCall();
      }
    },
    // 关闭点名弹窗
    closeRollCall() {
      this.rollCallVisible = false;
      this.rollCallAll = false;
      this.rollCallChoose = [];
      this.attendanceCount = 0;
      this.absenceAttendanceCount = 0;
    },
    // 打开/关闭单词跟读弹窗
    async onWordFollow() {
      if (!this.showWordFollow) {
        await this.initWordData(); // 单词跟读
      }
      this.showWordFollow = !this.showWordFollow;
    },
    // 加载单词跟读数据
    async initWordData() {
      // 拿session storage里的数据
      let cacheData = JSON.parse(sessionStorage.getItem("wordFollowData"));
      // 拿服务器数据
      let newData = (await getWordsList(this.resourceId)).data;
      // 对比单词
      let cacheWord =
        (cacheData && cacheData.map((i) => i.word).join(".")) || "";
      let newWord = (newData && newData.map((i) => i.word).join(".")) || "";
      // 获取缓存的sourceid，如果和现在的不一样就使用服务器数据
      let cacheSourceId =
        cacheData && cacheData.length && cacheData[0].resourceId;
      // 如果缓存数据的单词和服务器一致就使用缓存，不一致就使用服务器数据
      this.wordData =
        cacheWord === newWord && cacheSourceId === +this.resourceId
          ? cacheData
          : newData.map((i) => ({
              ...i,
              status: 1,
              score: 0,
            }));
      if (!(cacheWord === newWord && cacheSourceId === +this.resourceId)) {
        sessionStorage.setItem("wordFollowIndex", 0);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    var id = to.query.id;
    if (!id) {
      MessageBox.alert(
        this.$t("el.teaching.looseParams"),
        this.$t("el.common.reminder"),
        {
          type: "warning",
          showClose: false,
          closeOnPressEscape: false,
        }
      )
        .then(() => {
          next({ ...from });
        })
        .catch(() => {
          next({ ...from });
        });
    } else {
      queryKnowledgeInfo({
        knowledgeId: id,
      })
        .then(({ data }) => {
          // 校验课程知识点是否被停过，是否有权限。
          if (data.courseStatus || data.knowledgeStatus) {
            // 停用
            next({
              name: "Resource",
            });
          } else if (!data.courseAuth) {
            // 无权限
            next({
              name: "Auth",
            });
          } else {
            if (!to.query.resourceId) {
              queryResource("KJ", {
                knowledgeId: to.query.id,
                originTenantId: to.query.originTenantId,
              })
                .then(({ data }) => {
                  //
                  next((vm) => {
                    vm.knowledgeData = data || {};
                    // 选择课件、班级
                    vm.knowledgeId = to.query.id;
                    vm.classId = to.query.classId;
                    vm.resourceCategory = to.query.resourceCategory;
                    vm.gradeId = to.query.gradeId;
                    vm.classType = to.query.classType;
                    vm.className = to.query.className;
                    vm.schoolCode = to.query.schoolCode;

                    if (data.standardList && data.standardList.length > 0) {
                      vm.resourceId = data.standardList[0].resourceId;
                      vm.resourceType = data.standardList[0].resourceType;
                      vm.resourceName = data.standardList[0].resourceName;
                      vm.format = data.standardList[0].format;
                      vm.version = data.standardList[0].version;
                    } else if (
                      data.personalList &&
                      data.personalList.length > 0
                    ) {
                      vm.resourceId = data.personalList[0].resourceId;
                      vm.resourceType = data.personalList[0].resourceType;
                      vm.resourceName = data.personalList[0].resourceName;
                      vm.format = data.personalList[0].format;
                      vm.version = data.personalList[0].version;
                    }
                  });
                })
                .catch((err) => {});
            } else {
              next((vm) => {
                vm.knowledgeData = data || {};
                // 选择课件、班级
                vm.knowledgeId = to.query.id;
                vm.resourceId = to.query.resourceId;
                vm.classId = to.query.classId;
                vm.version = to.query.version;
                vm.resourceType = to.query.resourceType;
                vm.resourceName = to.query.resourceName;
                vm.resourceCategory = to.query.resourceCategory;
                vm.format = to.query.format || "";
                vm.gradeId = to.query.gradeId;
                vm.classType = to.query.classType;
                vm.className = to.query.className;
                vm.schoolCode = to.query.schoolCode;

                // vm.$refs.selectCourse.init(
                //   vm.knowledgeId,
                //   vm.resourceId,
                //   vm.classId
                // );
              });
            }
          }
        })
        .catch(() => {
          next((vm) => {
            MessageBox.confirm(
              vm.$t("el.teaching.dataLoadingFail"),
              vm.$t("el.common.reminder"),
              {
                type: "warning",
                showClose: false,
                closeOnPressEscape: false,
                closeOnClickModal: false,
                confirmButtonText: vm.$t("el.common.reload"),
                cancelButtonText: vm.$t("el.common.back"),
              }
            )
              .then(() => {
                next({ ...to });
              })
              .catch(() => {
                next({ ...from });
              });
          });
        });
    }
  },
};
</script>

<style lang="less" scoped>
.pages {
  position: fixed;
  bottom: 30px;
  right: 12px;
  width: 98px;
  height: 28px;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
  box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
  padding: 4px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  .page-back,
  .page-next {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #5a53f5;
  }
  .page-no-back {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #999;
    cursor: not-allowed;
  }
  .page-no-next {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #999;
    cursor: not-allowed;
  }
  .page-numbers {
    font-size: 14px;
    color: #5a53f5;
    line-height: 28px;
  }
}
#newwpsfile {
  display: block;
  width: 100%;
  height: 100%;
}
.teaching {
  iframe {
    display: block; // iframe是内联元素，会导致会有空白结束字符会有间隔。
    width: 100%;
    height: 100%;
    // height: 560px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  .whiteboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .whiteboard-exit {
    position: fixed;
    top: 48px;
    right: 12px;
    z-index: 100;
    font-size: 0;
    display: flex;
    .exit-screen,
    .exit-course {
      width: 36px;
      height: 36px;
      background: #646e93;
      box-shadow: 0px 4px 5px 0px rgba(91, 101, 138, 0.19);
      border-radius: 6px;
      // padding:7px;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 32px;
      opacity: 0.4;
      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin: 7px;
        // &:first-child {
        //   margin-bottom: 10px;
        //   margin-right: 7px;
        // }
      }
      &:hover {
        opacity: 1;
      }
    }
    .exit-screen {
      margin-right: 10px;
    }
    .exit-course {
      background-color: #5a53f5;
    }
  }
  .quanpin {
    text-align: center;
    text-indent: 10px;
    letter-spacing: 8px;
  }
  .el-dialog__content {
    font-size: 19px;
    color: #646c95;
    line-height: 37px;
    letter-spacing: 1px;
    text-align: center;
    // padding-top: 35px;
    // padding-bottom: 55px;
    a {
      color: @themeBlue;
    }
  }
  .el-dialog__hint {
    text-align: center;
    font-size: 32px;
    color: #282828;
    line-height: 37px;
    letter-spacing: 2px;
    padding-top: 12px;
    padding-bottom: 59px;
  }
  .el-dialog__header-icon {
    text-align: left;
    img {
      width: 50px;
      height: 38px;
      cursor: pointer;
    }
  }
  .el-dialog {
    .red {
      color: #f5222d;
    }
    span {
      display: block;
      overflow: hidden;
    }
    .el-button + .el-button {
      margin-left: 36px;
      float: right;
    }
  }
  /deep/ .el-dialog__header {
    font-weight: bold;
  }
  .el-dialog--stu-select-list {
    /deep/ .el-scrollbar__wrap {
      overflow-y: hidden;
      margin-right: 0 !important;
    }
    /deep/ .el-dialog {
      background: transparent;
      box-shadow: none;
    }
    /deep/ .el-dialog__header {
      .el-dialog__headerbtn {
        width: 35px;
        height: 35px;
        background-image: linear-gradient(
          rgb(135, 196, 255),
          rgb(85, 152, 253)
        );
        border-radius: 50%;
        .el-dialog__close {
          color: #fff;
        }
      }
    }
    /deep/ .el-dialog__body {
      padding: 0;
      .el-scrollbar_x {
        height: 110px;
        position: absolute;
        top: 250px;
        width: calc(100% - 215px);
        left: 0;
        right: 0;
        margin: auto;
      }
      .el-scrollbar__wrap {
        overflow-y: hidden;
        margin-right: 0 !important;
      }
    }
    /deep/ .el-dialog__call-over-img {
      width: 100%;
      img {
        max-width: 100%;
      }
      .call-over-draw {
        width: 82px;
        position: absolute;
        top: 96px;
        left: 363px;
      }
    }
    .stu-list__item {
      display: flex;
      width: 100%;
      height: 108px;
      font-size: 58px;
      font-weight: 600;
      border: none;
      border-radius: 7px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      & > div {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
      }
      cursor: pointer;
    }
  }
  // 名单
  .el-dialog--stu-list {
    /deep/ .el-dialog__header {
      text-align: left;
      line-height: 24px;
      padding: 16px 20px;
      font-size: 20px;
      font-weight: 500;
      color: #131313;
      line-height: 28px;
      .sub-title {
        display: inline;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 28px;
        vertical-align: top;
      }
    }
    /deep/ .el-dialog__body {
      padding: 0 20px;
      padding-right: 0;
      .stu-dialog-container {
        .tip {
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          color: #131313;
          line-height: 25px;
          margin-top: 4px;
        }
        .choose-all {
          text-align: left;
          .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: #409eff;
          }
          .el-checkbox__inner {
            border: 1px solid #333333;
          }
          .el-checkbox__label {
            font-size: 18px;
            font-weight: 500;
            color: #131313;
            line-height: 25px;
          }
        }
        .no-data {
          height: 100px;
          font-size: 16px;
          padding-top: 30px;
        }
      }
      .stu-footer {
        margin: 20px 0 30px;
        .stu-confirm-btn {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          line-height: 17px;
          padding: 6px 20px;
          background: #5a53f5;
          box-shadow: 0px 4px 5px 0px rgba(130, 83, 245, 0.19);
        }
      }
    }
  }
  .stu-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -25px;
    &.readonly {
      li {
        cursor: default;
      }
    }
    li {
      position: relative;
      box-sizing: border-box;
      text-align: center;
      margin-left: 25px;
      margin-bottom: 25px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #979797;
      padding: 12px;
      font-size: 18px;
      font-weight: 500;
      color: #414141;
      line-height: 25px;
      width: 116px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      .icon {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        img {
          width: 28px;
        }
      }
      &.rolled {
        border: 1px solid #cfcfcf;
        color: #666666;
        cursor: not-allowed;
        .icon {
          display: block;
        }
      }
      &.choose {
        border: 1px solid #5a53f5;
        background: #f1f0ff;
        color: #5a53f5;
      }
    }
  }
  .call-over-operate {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    img {
      width: 180px;
      cursor: pointer;
    }
  }
}
.en-status {
  .teaching {
    /deep/ .el-dialog .el-button + .el-button {
      margin-left: 10px;
    }
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  //   &::after {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     right: 1px;
  //     bottom: 1px;
  //     width: 39px;
  //     height: 21px;
  //     background: #d24726;
  //   }
}
.setbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 352px);
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.sksetbutton {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 85px);
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.setfloorbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 252px);
  cursor: not-allowed;
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.sksetfloorbutton {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: calc(50% + 5px);
  cursor: not-allowed;

  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.en-status {
  .sksetfloorbutton {
    right: calc(50% + 15px);
  }
}
.mask-show {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  //   &::after {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     right: 1px;
  //     bottom: 1px;
  //     width: 39px;
  //     height: 21px;
  //     background: #d24726;
  //   }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: 30px;
    width: 350px;
    height: 22px;
    background: #393a3d;
  }
}
// .hide-edit {
//   position: absolute;
//   height: 22px;
//   width: 36px;
//   bottom: 0px;
//   right: 75px;
//   background-color: #d24726;
// }
.uls-enter-active {
  transform: translateY(0);
  transition: all 0.1s ease;
}
.uls-leave-active {
  transform: translateY(-100%);
  transition: all 0.1s ease;
  opacity: 1;
}
.uls-enter {
  transform: translateY(100%);
  opacity: 0;
}
.uls-leave {
  transform: translateY(0);
  opacity: 0;
}
.ul-test {
  height: 110px;
  margin: 0;
  padding: 0;
}
.lis {
  width: 100%;
  height: 108px;
  line-height: 108px;
}
.box-wrapper {
  display: none;
  position: absolute;
  width: calc(100% - 215px);
  height: 108px;
  top: 250px;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  box-shadow: #3a5eff 0px 0px 5px 4px;
  z-index: 999;
  border-radius: 15px;
}
.animation-flash {
  animation: myfirst 0.3s linear;
  -webkit-animation: myfirst 0.3s linear;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
}
.animation-words {
  animation: wordsshine 0.3s linear;
  -webkit-animation: wordsshine 0.3s linear;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
}
.animation-draw {
  animation: drawshine 0.3s linear;
  -webkit-animation: drawshine 0.3s linear;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
}
@keyframes drawshine {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1, 1);
  }
}
@-webkit-keyframes drawshine {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1, 1);
  }
}
@keyframes wordsshine {
  0% {
    color: #3a5eff;
  }
  100% {
    color: #fff;
  }
}
@-webkit-keyframes wordsshine {
  0% {
    color: #3a5eff;
  }
  100% {
    color: #fff;
  }
}
@keyframes myfirst {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.55;
  }
  75% {
    opacity: 0.35;
  }
  100% {
    box-shadow: #fff 0px 0px 5px 4px;
    opacity: 1;
  }
}
@-webkit-keyframes myfirst {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.55;
  }
  75% {
    opacity: 0.35;
  }
  100% {
    box-shadow: #fff 0px 0px 5px 4px;
    opacity: 1;
  }
}
.whiteboard-word,
.whiteboard-course-word {
  display: inline-block;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
</style>
